import React, {Fragment, useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Call from "../../hocs/call";
import {
  CRITERIA_SELECTION_MODE_ALL,
  CRITERIA_SELECTION_MODE_STEP_BY_STEP,
  CRITERIA_SELECTION_TYPE_DYNAMIC,
  fetchDatasetStructureCodelist,
  fetchDatasetStructureCodelists,
  setDatasetStructureCodelistCriteria,
  setDatasetStructureCodelistTimePeriod
} from "../../state/dataset/datasetActions";
import EnhancedTree from "../enhanced-tree";
import _ from "lodash"
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck"
import Tooltip from "@material-ui/core/Tooltip";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InfiniteScrollTable from "../infinite-scroll-table";
import TimePeriod from "./TimePeriod";
import Typography from '@material-ui/core/Typography';
import {withTranslation} from "react-i18next";
import {TIME_PERIOD_DIMENSION_KEY} from "../../utils/jsonStat";
import TextField from "@material-ui/core/TextField";
import CustomEmpty from "../custom-empty";
import {CRITERIA_FILTER_TYPE_CODES, CRITERIA_FILTER_TYPE_STRING} from "../../utils/criteria";
import IconButton from "@material-ui/core/IconButton";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import Grid from "@material-ui/core/Grid";

const $ = window.jQuery;

const styles = theme => ({
  root: {
    height: 440,
    width: "100%"
  },
  criteriaContainer: {
    width: "100%",
    padding: "16px 24px 0",
    overflowY: "hidden"
  },
  criteriaContainerDimension: {
    height: "100%"
  },
  codelistInfo: {
    width: "100%",
    padding: "8px 0"
  },
  codelistContainer: {
    width: "100%",
  },
  emptyContainer: {
    height: "100%",
    width: "100%"
  },
  timePeriodContainer: {
    height: "100%",
    width: "100%"
  },
  treeActions: {
    marginBottom: 8
  },
  tabLabel: {
    display: "-webkit-box",
    lineClamp: 3,
    boxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const mapDispatchToProps = dispatch => ({
  fetchCodelist: ({nodeId, datasetId, dimensionId, type, criteria, defaultLastNPeriods}) =>
    dispatch(fetchDatasetStructureCodelist(nodeId, datasetId, dimensionId, type, criteria, defaultLastNPeriods)),
  fetchCodelists: ({nodeId, datasetId, type, defaultLastNPeriods}) =>
    dispatch(fetchDatasetStructureCodelists(nodeId, datasetId, type, defaultLastNPeriods)),
  onSetCriteria: criteria => dispatch(setDatasetStructureCodelistCriteria(criteria)),
  onSetTimePeriod: timePeriod => dispatch(setDatasetStructureCodelistTimePeriod(timePeriod)),
});

const handleStyle = dimId => {
  const headerHeight = $("#criteria__header").outerHeight(true) || 0;
  $("#criteria__container").height(`calc(100% - ${headerHeight}px)`);

  const codelistInfoHeight = $(`#dimension__${dimId} #codelist-info`).outerHeight(true) || 0;
  $(`#dimension__${dimId} #codelist-container`).height(`calc(100% - ${codelistInfoHeight}px)`);
};

function Criteria(props) {
  const {
    t,
    classes,
    nodeId,
    datasetId,
    dimensions,
    freqDimension,
    mode,
    type,
    codes,
    isCodesFlat,
    codelists,
    areCodelistsFlat,
    codelistsLength,
    criteria,
    timePeriod,
    fetchCodelist,
    fetchCodelists,
    onSetCriteria,
    onSetTimePeriod,
    isCriteriaValid,
    setCriteriaValidity,
    defaultLastNPeriods
  } = props;

  const [tabId, setTabId] = useState(0);
  const [isCallDisabled, setIsCallDisabled] = useState(false);

  const [tmpCriteria, setTmpCriteria] = useState(null);
  const [tmpTabId, setTmpTabId] = useState(null);

  const [lastNotCodedFilter, setLastNotCodedFilter] = useState(null);

  useEffect(() => {
    const fun = () => dimensions[tabId]
      ? handleStyle(dimensions[tabId].id)
      : null;

    window.addEventListener("resize", fun);

    return () => {
      window.removeEventListener("resize", fun);
    }
  }, [dimensions, tabId]);

  useEffect(() => {
    if (dimensions[tabId]) {
      handleStyle(dimensions[tabId].id);
    }
  });

  const handleTabChange = newTabId => {
    const newCriteria = _.cloneDeep(criteria);

    const dimension = dimensions[tabId].id;
    const filterValue = criteria?.[dimension]?.filterValues?.[0];
    if (type === CRITERIA_SELECTION_TYPE_DYNAMIC && !dimensions[tabId]?.extra?.DataStructureRef && filterValue) {
      setLastNotCodedFilter({dimension, filterValue});
    }

    if (type === CRITERIA_SELECTION_TYPE_DYNAMIC && newTabId < (dimensions.length - 1)) {

      const criteriaToRemove = dimensions
        .map(({id}) => id)
        .slice(newTabId + 1)
        .filter(dim => newCriteria[dim] !== null && newCriteria[dim] !== undefined);

      if (criteriaToRemove.length > 0) {
        criteriaToRemove.forEach(dim => newCriteria[dim] = undefined);
        setTmpCriteria(newCriteria);
        setTmpTabId(newTabId);

      } else {
        setTabId(newTabId);
        setIsCallDisabled(false);
      }

    } else {
      setTabId(newTabId);
      setIsCallDisabled(false);
    }
  };

  const handleCheck = (checkedKeys, type) => {
    const newCriteria = _.cloneDeep(criteria);
    const dimensionId = dimensions[tabId].id;

    let newDimensionCriteria = undefined;

    if (checkedKeys && ((checkedKeys.length === 1 && checkedKeys[0] !== "") || checkedKeys.length > 1)) {
      newDimensionCriteria = {
        id: dimensionId,
        type: (type || CRITERIA_FILTER_TYPE_CODES),
        filterValues: checkedKeys,
        period: null,
        from: null,
        to: null
      };
    }

    newCriteria[dimensionId] = newDimensionCriteria;

    onSetCriteria(newCriteria);
  };

  const handleFetch = ({dimensionId, criteria: newCriteria}) => {
    const param = {
      nodeId,
      datasetId,
      dimensionId,
      type,
      criteria: newCriteria,
      defaultLastNPeriods
    };

    if (mode === CRITERIA_SELECTION_MODE_STEP_BY_STEP) {
      fetchCodelist(param);
    } else {
      fetchCodelists(param);
    }

    setIsCallDisabled(true);
  };

  return (
    <div className={classes.root}>
      {dimensions && tabId !== null && (
        <Fragment>
          <Box id="criteria__header">
            <Tabs
              value={tabId}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(event, newValue) => handleTabChange(newValue)}
            >
              {(dimensions || []).map((dim, idx) => (
                <Tab
                  key={idx}
                  label={
                    <Tooltip title={dim.label ? `[${dim.id}] ${dim.label}` : dim.id}>
                      <Grid container justify="center" direction="column" alignItems="center">
                        <Grid item>
                          <span className={classes.tabLabel}>
                            {dim.label || dim.id}
                          </span>
                        </Grid>
                        {codelistsLength?.[idx] && (
                          <Grid item>
                            {`(${(criteria[dim.id]?.filterValues || []).length}/${codelistsLength[idx]})`}
                          </Grid>
                        )}
                      </Grid>
                    </Tooltip>
                  }
                  id={`tab__${dim.id}`}
                  aria-controls={`dimension__${dim.id}`}
                  tabIndex={0}
                  disabled={!isCriteriaValid}
                />
              ))}
            </Tabs>
          </Box>
          <div
            id="criteria__container"
            className={classes.criteriaContainer}
          >
            {(dimensions || []).map((dim, idx) => (
              <div
                key={idx}
                id={`dimension__${dim.id}`}
                aria-labelledby={`tab__${dim.id}`}
                hidden={idx !== tabId}
                className={classes.criteriaContainerDimension}
              >
                <Call
                  cb={handleFetch}
                  cbParam={{
                    dimensionId: dimensions[tabId].id,
                    criteria: {
                      ...criteria,
                      [dimensions[tabId].id]: undefined
                    }
                  }}
                  disabled={idx !== tabId || (mode === CRITERIA_SELECTION_MODE_ALL && !!codelists) || isCallDisabled}
                >
                  {(() => {
                    const data = mode === CRITERIA_SELECTION_MODE_STEP_BY_STEP
                      ? codes
                      : codelists?.[tabId];

                    if (!data) {
                      return (
                        <div className={classes.emptyContainer}>
                          <CustomEmpty/>
                        </div>
                      )

                    } else if (dimensions[tabId].id === TIME_PERIOD_DIMENSION_KEY) {
                      return (
                        <div className={classes.timePeriodContainer}>
                          <TimePeriod
                            timePeriod={timePeriod}
                            freqDimension={freqDimension}
                            onSetTimePeriod={onSetTimePeriod}
                            isCriteriaValid={isCriteriaValid}
                            setCriteriaValidity={setCriteriaValidity}
                          />
                        </div>
                      )

                    } else {
                      const checkedKeys = criteria[dimensions[tabId].id]?.filterValues || [];
                      const isCheckDisabled = ((data || []).length === 1 && (data[0].children || []).length === 0);

                      return (
                        <Fragment>
                          <Typography id="codelist-info" className={classes.codelistInfo}>
                            {`${dimensions[tabId].label || dimensions[tabId].id}${dimensions[tabId]?.extra?.DataStructureRef ? ` (${dimensions[tabId].extra.DataStructureRef})` : ""}`}
                          </Typography>
                          <div id="codelist-container" className={classes.codelistContainer}>
                            {(data.length === 0)
                              ? dimensions[tabId]?.extra?.DataStructureRef
                                ? (
                                  <CustomEmpty
                                    text={lastNotCodedFilter
                                      ? t("components.criteria.dialogs.warning.notCodedDimension.label", lastNotCodedFilter)
                                      : t("components.criteria.emptyCodelist.label")
                                    }
                                  />
                                )
                                : (
                                  <Fragment>
                                    <div style={{margin: "16px 0"}}>
                                      {t("components.criteria.noCodelistAssociated.info")}
                                    </div>
                                    <TextField
                                      value={checkedKeys[0] || ""}
                                      placeholder={t("components.criteria.noCodelistAssociated.noFilter")}
                                      variant="outlined"
                                      style={{width: "100%"}}
                                      onChange={({target}) => {
                                        const newCheckedKeys = (target.value || "").length === 0 ? [] : [target.value];
                                        handleCheck(newCheckedKeys, CRITERIA_FILTER_TYPE_STRING)
                                      }}
                                    />
                                  </Fragment>
                                )
                              : (isCodesFlat === true || areCodelistsFlat?.[tabId] === true)
                                ? (
                                  <InfiniteScrollTable
                                    data={data}
                                    getRowKey={({id}) => id}
                                    showHeader={false}
                                    columns={[
                                      {
                                        title: "",
                                        dataIndex: 'name',
                                        render: (_, {id, name}) => `[${id}] ${name}`,
                                        renderText: (_, {id, name}) => `[${id}] ${name}`,
                                        minWidthToContent: true,
                                        noFilter: true
                                      }
                                    ]}
                                    rowSelection={{
                                      selectedRowKeys: checkedKeys,
                                      onChange: handleCheck,
                                    }}
                                    leftActions={
                                      <Fragment>
                                        <Tooltip title={t("components.criteria.table.actions.selectAll.tooltip")}>
                                          <span>
                                            <IconButton
                                              aria-label={t("components.criteria.table.actions.selectAll.ariaLabel")}
                                              onClick={() => handleCheck(data.map(({id}) => id))}
                                              style={{padding: 8}}
                                              disabled={isCheckDisabled}
                                            >
                                              <LibraryAddCheckIcon/>
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                        <Tooltip title={t("components.criteria.table.actions.deselectAll.tooltip")}>
                                          <span>
                                            <IconButton
                                              aria-label={t("components.criteria.table.actions.deselectAll.ariaLabel")}
                                              onClick={() => handleCheck([])}
                                              style={{padding: 8}}
                                              disabled={isCheckDisabled}
                                            >
                                              <FilterNoneIcon style={{padding: 1}}/>
                                            </IconButton>
                                          </span>
                                        </Tooltip>
                                      </Fragment>
                                    }
                                    isCheckDisabled={isCheckDisabled}
                                  />
                                )
                                : (
                                  <EnhancedTree
                                    key={tabId}
                                    tree={data}
                                    getNodeKey={({id}) => id}
                                    childrenKey="children"
                                    labelKey="name"
                                    getNodeLabel={node => `[${node.id}] ${node.name}`}
                                    isLabelNotMultilingual
                                    checkable
                                    withLevelSelector
                                    checkedKeys={checkedKeys}
                                    getIsCheckable={node => node.isSelectable !== false}
                                    // defaultExpandedKeys={criteria[dimensions[tabId].id] || []}
                                    onCheck={checkedKeys => handleCheck(checkedKeys)}
                                    isCheckDisabled={isCheckDisabled}
                                    nodeActions={[
                                      node => (node.children && node.children.length > 0)
                                        ? {
                                          icon: <PlaylistAddCheckIcon/>,
                                          title: t("components.criteria.tree.nodeActions.selectAll.title"),
                                          onClick: node => {
                                            let newCheckedKeys = [...checkedKeys];
                                            const childrenKeys = (node.children || []).filter(({isSelectable}) => isSelectable !== false).map(({id}) => id)
                                            const unselectedChildrenKeys = childrenKeys.filter(key => !newCheckedKeys.includes(key))

                                            newCheckedKeys = (unselectedChildrenKeys.length > 0)
                                              ? newCheckedKeys.concat(childrenKeys)
                                              : newCheckedKeys.filter(key => !childrenKeys.includes(key));
                                            handleCheck(_.uniq(newCheckedKeys));
                                          }
                                        }
                                        : null
                                    ]}
                                  />
                                )
                            }
                          </div>
                        </Fragment>
                      )
                    }
                  })()}
                </Call>
              </div>
            ))}
          </div>

          <Dialog
            open={tmpCriteria !== null}
            fullWidth
            maxWidth="sm"
            onClose={() => setTmpCriteria(null)}
          >
            <DialogContent>
              {t("components.criteria.dialogs.warning.losingFilters.title")}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setTmpCriteria(null);
                  setTmpTabId(null);
                }}
              >
                {t("commons.confirm.cancel")}
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  setTmpCriteria(null);
                  onSetCriteria(tmpCriteria);
                  setTmpTabId(null);
                  setTabId(tmpTabId);
                  handleFetch({
                    dimensionId: dimensions[tmpTabId].id,
                    criteria: {
                      ...tmpCriteria,
                      [dimensions[tmpTabId].id]: undefined
                    }
                  });
                }}
                color="primary"
              >
                {t("commons.confirm.confirm")}
              </Button>
            </DialogActions>
          </Dialog>

        </Fragment>
      )}
    </div>
  )
}

export default compose(
  withStyles(styles),
  withTranslation(),
  connect(null, mapDispatchToProps)
)(Criteria);
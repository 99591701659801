import React, {Fragment, useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  DASHBOARD_ELEM_ENABLE_FILTERS_KEY,
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_SHOW_TITLE_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_TEXT,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY,
  DASHBOARD_ELEM_WIDTH_KEY,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";
import {getViewerIdxFromType} from "../data-viewer";
import {localizeI18nObj} from "../../utils/i18n";
import {
  DOWNLOAD_FORMAT_IMAGE,
  DOWNLOAD_VIEWER_CHART,
  DOWNLOAD_VIEWER_MAP,
  DOWNLOAD_VIEWER_TABLE,
  exportViewerCanvas,
  getDownloadFormatExtensionFromFormat,
  getDownloadFormatLabelFromFormat,
  getDownloadFormatViewersFromFormat,
  isDownloadFormatValid
} from "../../utils/download";
import SanitizedHTML from "../sanitized-html";
import CustomEmpty from "../custom-empty";
import {
  DASHBOARD_VIEW_STATE_APPLY_FILTERS,
  DASHBOARD_VIEW_STATE_ERROR,
  DASHBOARD_VIEW_STATE_ERROR_FETCHING_GEOMETRIES,
  DASHBOARD_VIEW_STATE_FETCHING
} from "../../state/dashboard/dashboardReducer";
import {
  getDatasetAttributeList,
  getDimensionAttributeMap,
  getDimensionLabelFromJsonStat,
  getDimensionValueLabelFromJsonStat,
  getFilterCombinationCount,
  getSeriesAttributeList,
  TIME_PERIOD_DIMENSION_KEY,
  VARIATION_DIMENSION_KEY
} from "../../utils/jsonStat";
import DatasetFilters from "../dataset-filters";
import Table from "../table";
import Map from "../map";
import Chart from "../chart";
import _ from "lodash";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import {v4 as uuidv4} from 'uuid';
import GetAppIcon from '@material-ui/icons/GetApp';
import Card from "@material-ui/core/Card";
import ButtonSelect from "../button-select";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../custom-dialog-title";
import Button from "@material-ui/core/Button";
import AttributeList from "../attribute-list";
import AttributeButton from "../attribute-button";
import {LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID} from "../label-format-selector/constants";
import {toggleFullScreen} from "../../utils/other";

const $ = window.jQuery;

const VIEW_FIXED_HEIGHT = 580;

const styles = theme => ({
  col: {
    display: "inline-block",
    verticalAlign: "top",
    padding: 8
  },
  viewContainer: {
    position: "relative",
    padding: "16px 24px"
  },
  viewContainerHeader: {},
  viewContainerHeaderTitle: {
    minHeight: 48,
    paddingBottom: 8,
    display: "flex",
    alignItems: "center",
    fontSize: 18,
    "& > button": {
      marginLeft: 4
    }
  },
  viewContainerHeaderControllers: {
    position: "absolute",
    top: 16,
    right: 24,
    display: "flex",
    "& > *": {
      marginLeft: 4
    }
  },
  viewContainerHeaderStaticFilters: {
    paddingBottom: 8,
  },
  viewContainerHeaderActiveFilters: {
    paddingBottom: 8,
  },
  viewContainerHeaderPlaceholder: {
    paddingBottom: 8
  },
  viewContainerView: {
    width: "100%",
    position: "relative"
  },
  attributeId: {
    cursor: "default",
    fontSize: 13,
    color: "rgb(255, 255, 255)",
    backgroundColor: "rgb(108, 108, 108)",
    borderRadius: 3,
    padding: "0 4px",
    marginLeft: 4
  },
  attributeAst: {
    cursor: "default",
    fontSize: 15,
    color: theme.palette.primary.main,
    fontFamily: "Do Hyeon",
    marginLeft: 4
  }
});

const handleStyle = (dashboardId, viewIdx, dashboardElem, minDashboardWidth) => {
  const $viewContainer = $(`#dashboard__${dashboardId}__view-container__${viewIdx}`);

  if ($viewContainer.is(":visible")) {

    const $col = $(`#dashboard__${dashboardId}__col__${viewIdx}`);
    const $header = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header`);
    const $title = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__title`);
    const $controllers = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__controllers`);
    const $placeHolder = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__placeholder`);
    const $staticFilters = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__static-filters`);
    const $activeFilters = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__header__active-filters`);
    const $view = $(`#dashboard__${dashboardId}__view-container__${viewIdx}__view`);

    $col.css({
      width: `${window.innerWidth > minDashboardWidth ? dashboardElem[DASHBOARD_ELEM_WIDTH_KEY] : 100}%`
    });

    if ($title.length) {
      $title.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
      $staticFilters.css({
        width: "100%"
      });
      $activeFilters.css({
        width: "100%"
      });

    } else if ($staticFilters.length) {
      $staticFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });
      $activeFilters.css({
        width: "100%"
      });

    } else if ($activeFilters.length) {
      $activeFilters.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight(),
        paddingTop: 12
      });

    } else {
      $placeHolder.css({
        maxWidth: `calc(100% - ${$controllers.outerWidth() || 0}px)`,
        minHeight: $controllers.outerHeight()
      });
    }

    $view.css({
      height: `calc(100% - ${$header.outerHeight() || 0}px)`
    });
  }
};

function DashboardCol(props) {

  const {
    t,
    classes,
    hub,
    defaultLanguage,
    languages,
    minDashboardWidth = 960,
    dashboardId,
    dashboard,
    filterValue,
    rowIdx,
    colIdx,
    dashboardElem,
    jsonStat: externalJsonStat,
    layout: externalLayoutObj,
    filterTree: externalFilterTree,
    timePeriodsByFreq: externalTimePeriodsByFreq,
    map: externalMap,
    rowWithView,
    onSelect,
    fetchMapGeometries,
    onDownloadSubmit
  } = props;

  const [chartId] = useState("chart__" + uuidv4());
  const [mapId] = useState("map__" + uuidv4());

  const [jsonStat, setJsonStat] = useState(externalJsonStat);
  const [layoutObj, setLayoutObj] = useState(externalLayoutObj);
  const [filterTree, setFilterTree] = useState(externalFilterTree);
  const [timePeriodsByFreq, setTimePeriodsByFreq] = useState(externalTimePeriodsByFreq);
  const [map, setMap] = useState(externalMap);

  const [isFullscreen, setFullscreen] = useState(false);
  const [isAttributesVisible, setAttributesVisibility] = useState(false);

  const viewIdx = getViewIdxFromRowAndCol(rowIdx, colIdx);

  useEffect(() => {
    const func = () => handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
    window.addEventListener("resize", func);
    return () => window.removeEventListener("resize", func)
  }, [dashboardId, viewIdx, dashboardElem, minDashboardWidth]);

  useEffect(() => {
    handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
  });

  useEffect(() => {
    setJsonStat(prevJsonStat => {
      if (_.isEqual(prevJsonStat, externalJsonStat)) {
        return prevJsonStat
      } else {
        return externalJsonStat
      }
    });
  }, [externalJsonStat]);

  useEffect(() => {
    setLayoutObj(prevLayoutObj => {
      if (_.isEqual(prevLayoutObj, externalLayoutObj)) {
        return prevLayoutObj
      } else {
        return externalLayoutObj
      }
    });
  }, [externalLayoutObj]);

  useEffect(() => {
    setFilterTree(prevFilterTree => {
      if (_.isEqual(prevFilterTree, externalFilterTree)) {
        return prevFilterTree
      } else {
        return externalFilterTree
      }
    });
  }, [externalFilterTree]);

  useEffect(() => {
    setTimePeriodsByFreq(prevTimePeriodsByFreq => {
      if (_.isEqual(prevTimePeriodsByFreq, externalTimePeriodsByFreq)) {
        return prevTimePeriodsByFreq
      } else {
        return externalTimePeriodsByFreq
      }
    });
  }, [externalTimePeriodsByFreq]);

  useEffect(() => {
    setMap(prevMaps => {
      if (_.isEqual(prevMaps, externalMap)) {
        return prevMaps
      } else {
        return externalMap
      }
    });
  }, [externalMap]);

  const handleFullscreen = () => {
    toggleFullScreen(`dashboard__${dashboardId}__view-container__${viewIdx}`, `dashboard__${dashboardId}__col__${viewIdx}`, !isFullscreen);
    handleStyle(dashboardId, viewIdx, dashboardElem, minDashboardWidth);
    setFullscreen(!isFullscreen);
  };

  const view = _.cloneDeep((dashboard.views || {})[dashboardElem[DASHBOARD_ELEM_VALUE_KEY]]);
  const viewerIdx = view ? getViewerIdxFromType(view.defaultView) : null;

  const nodeMinimalInfo = view ? hub.nodes.find(({nodeId}) => nodeId === view.nodeId) : {};
  const nodeExtras = nodeMinimalInfo?.extras || [];
  const nodeDownloadFormats = JSON.parse(nodeExtras.find(({key}) => key === "DownloadFormats")?.value || "[]");
  const downloadFormats = [];
  nodeDownloadFormats.forEach(format => {
    if (isDownloadFormatValid(format)) {
      downloadFormats.push({
        label: getDownloadFormatLabelFromFormat(format, t),
        format: format,
        extension: getDownloadFormatExtensionFromFormat(format),
        viewers: getDownloadFormatViewersFromFormat(format)
      })
    }
  });

  const nodeHiddenAttributes = JSON.parse(nodeExtras.find(({key}) => key === "HiddenAttributes")?.value || "[]");
  const datasetAttributes = (jsonStat && layoutObj) ? getDatasetAttributeList(jsonStat, nodeHiddenAttributes) : [];
  const seriesAttributes = (jsonStat && layoutObj) ? getSeriesAttributeList(jsonStat, layoutObj.labelFormat, nodeHiddenAttributes) : [];
  const showAttributeIcon = datasetAttributes.concat(seriesAttributes).length > 0;

  return (
    <div
      id={`dashboard__${dashboardId}__col__${viewIdx}`}
      className={`${classes.col} dashboard__col`}
    >
      <Card
        id={`dashboard__${dashboardId}__view-container__${viewIdx}`}
        className={`${classes.viewContainer} dashboard__view-container`}
        style={{
          width: "100%",
          height: rowWithView ? VIEW_FIXED_HEIGHT : "100%",
          overflowY: dashboardElem[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW
            ? "hidden"
            : "auto",
          overflowX: "hidden"
        }}
      >
        {(() => {
          if (dashboardElem[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_TEXT) {
            return (
              <SanitizedHTML
                html={localizeI18nObj(dashboardElem[DASHBOARD_ELEM_VALUE_KEY], defaultLanguage, languages)}
                allowTarget
              />
            )

          } else if (view === null || view === undefined) {
            return (
              <CustomEmpty
                text={t("components.dashboard.missingView")}
              />
            )

          } else if (jsonStat !== null && jsonStat !== undefined) {

            if (jsonStat === DASHBOARD_VIEW_STATE_APPLY_FILTERS) {
              return (
                <CustomEmpty
                  text={t("components.dashboard.applyFilters")}
                />
              )

            } else if (jsonStat === DASHBOARD_VIEW_STATE_FETCHING) {
              return (
                <CustomEmpty
                  text={t("components.dashboard.fetching") + "..."}
                  image={<CircularProgress/>}
                />
              )

            } else if (jsonStat === DASHBOARD_VIEW_STATE_ERROR) {
              return (
                <CustomEmpty
                  text={t("components.dashboard.fetchingDatasetError")}
                />
              )

            } else if (jsonStat === DASHBOARD_VIEW_STATE_ERROR_FETCHING_GEOMETRIES) {
              return (
                <CustomEmpty
                  text={t("components.dashboard.fetchingGeometriesError")}
                />
              )

            } else if (jsonStat === "") {
              return (
                <CustomEmpty
                  text={t("components.dashboard.emptyView")}
                />
              )

            } else if (layoutObj) {

              let layout = {...layoutObj.layout};

              const filter = dashboardElem[DASHBOARD_ELEM_FILTER_DIMENSION_KEY];
              if (filter && filterValue && (jsonStat.dimension[filter]?.category?.index || []).includes(filterValue)) {
                if ((layout.filters || []).includes(filter)) {
                  layout.filtersValue = {
                    ...layout.filtersValue,
                    [filter]: filterValue
                  };
                } else if ((layout.primaryDim || []).includes(filter)) {
                  layout.primaryDimValues = [filterValue];
                } else if ((layout.secondaryDim || []).includes(filter)) {
                  layout.secondaryDimValues = [filterValue];
                }
              }

              let staticFilters = [];
              layout.filters.forEach(dim => {
                if (!dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] || jsonStat.size[jsonStat.id.indexOf(dim)] === 1) {
                  const value = layout.filtersValue[dim];
                  staticFilters.push({
                    dim: dim,
                    dimLabel: getDimensionLabelFromJsonStat(jsonStat, dim, layoutObj.labelFormat),
                    value: value,
                    valueLabel: getDimensionValueLabelFromJsonStat(jsonStat, dim, value, layoutObj.labelFormat)
                  });
                }
              });

              let dimAttributeMap = getDimensionAttributeMap(jsonStat);

              const TitleComponent = (
                <div
                  id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__title`}
                  className={`${classes.viewContainerHeaderTitle} dashboard__view-container__header__title`}
                >
                  {localizeI18nObj(view.title, defaultLanguage, languages)}
                  {showAttributeIcon && (
                    <Tooltip title={t("components.dashboard.actions.attributes")}>
                      <AttributeButton
                        aria-label={t("components.dashboard.actions.attributes")}
                        color="primary"
                        onClick={() => setAttributesVisibility(true)}
                        iconStyle={{fontWeight: "normal"}}
                      />
                    </Tooltip>
                  )}
                </div>
              );

              const ControllersComponent = (
                <div
                  id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__controllers`}
                  className={`${classes.viewContainerHeaderControllers} dashboard__view-container__header__controllers`}
                >
                  {showAttributeIcon && !dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY] && (
                    <Tooltip title={t("components.dashboard.actions.attributes")}>
                      <AttributeButton
                        color="primary"
                        onClick={() => setAttributesVisibility(true)}
                        iconStyle={{fontWeight: "normal"}}
                      />
                    </Tooltip>
                  )}
                  <Tooltip
                    title={isFullscreen
                      ? t("components.dashboard.actions.fullscreen.exit")
                      : t("components.dashboard.actions.fullscreen.enter")
                    }
                  >
                    <IconButton
                      aria-label={isFullscreen
                        ? t("components.dashboard.actions.fullscreen.exit")
                        : t("components.dashboard.actions.fullscreen.enter")
                      }
                      color="primary"
                      onClick={handleFullscreen}
                    >
                      {isFullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
                    </IconButton>
                  </Tooltip>
                  {(() => {
                    const formats = downloadFormats.filter(({viewers}) => {
                      if (viewerIdx === 0) {
                        return !viewers || viewers.includes(DOWNLOAD_VIEWER_TABLE);
                      } else if (viewerIdx === 1) {
                        return !viewers || viewers.includes(DOWNLOAD_VIEWER_MAP);
                      } else {
                        return !viewers || viewers.includes(DOWNLOAD_VIEWER_CHART);
                      }
                    });

                    return formats.length > 0
                      ? (
                        <ButtonSelect
                          icon={<GetAppIcon/>}
                          tooltip={t("components.dashboard.actions.download.tooltip")}
                          ariaLabel={t("components.dashboard.actions.download.ariaLabel")}
                          color="primary"
                          onChange={({format, extension}) => {
                            if (format === DOWNLOAD_FORMAT_IMAGE) {
                              if (viewerIdx === 1) {
                                window.LMap.getCanvas(
                                  mapId,
                                  canvas => {
                                    exportViewerCanvas(
                                      canvas,
                                      `${localizeI18nObj(view.title, defaultLanguage, languages)}.jpeg`,
                                      layoutObj.mapSettings.mapShowTitle === true
                                        ? localizeI18nObj(layoutObj.mapSettings.mapTitle, defaultLanguage, languages)
                                        : null,
                                      layoutObj.mapSettings.mapShowFilters === true
                                        ? layoutObj.layout
                                        : null,
                                      layoutObj.mapSettings.mapShowFilters === true
                                        ? jsonStat
                                        : null
                                    );
                                  }
                                );
                              } else if (viewerIdx >= 2) {
                                const chartCanvas = document.getElementById(chartId);
                                exportViewerCanvas(
                                  chartCanvas,
                                  `${localizeI18nObj(view.title, defaultLanguage, languages)}.jpeg`,
                                  layoutObj.chartSettings.chartShowTitle === true
                                    ? localizeI18nObj(layoutObj.chartSettings.chartTitle, defaultLanguage, languages)
                                    : null,
                                  layoutObj.chartSettings.chartShowFilters === true
                                    ? layoutObj.layout
                                    : null,
                                  layoutObj.chartSettings.chartShowFilters === true
                                    ? jsonStat
                                    : null
                                );
                              }
                            } else {
                              const newCriteria = {...view.criteria};
                              Object.keys(layoutObj.layout.filtersValue).forEach(key => {
                                if (key !== TIME_PERIOD_DIMENSION_KEY) {
                                  newCriteria[key] = {
                                    id: key,
                                    filterValues: [layoutObj.layout.filtersValue[key]]
                                  }
                                }
                              });
                              const primaryDim = layoutObj.layout?.primaryDim?.[0] || null;
                              if (primaryDim && primaryDim !== TIME_PERIOD_DIMENSION_KEY) {
                                newCriteria[primaryDim] = {
                                  id: primaryDim,
                                  filterValues: layoutObj.layout.primaryDimValues
                                }
                              }
                              const secondaryDim = layoutObj.layout?.secondaryDim?.[0] || null;
                              if (secondaryDim && secondaryDim !== TIME_PERIOD_DIMENSION_KEY) {
                                newCriteria[secondaryDim] = {
                                  id: secondaryDim,
                                  filterValues: layoutObj.layout.secondaryDimValues
                                }
                              }

                              const exportParams = {
                                decimalNumber: view.decimalNumber,
                                decimalSeparator: localizeI18nObj(view.decimalSeparator, defaultLanguage, languages),
                                emptyCellPlaceHolder: layoutObj.tableEmptyChar,
                                labelFormat: layoutObj.labelFormat,
                                customLabelFormat: {
                                  [TIME_PERIOD_DIMENSION_KEY]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                                },
                                hasVariation: jsonStat.id.includes(VARIATION_DIMENSION_KEY),
                                showTrend: layoutObj.showTrend,
                                showCyclical: layoutObj.showCyclical,
                                exportConfig: hub.hub.exportConfig
                              };

                              onDownloadSubmit({
                                nodeId: view.nodeId,
                                datasetId: view.datasetId,
                                datasetTitle: localizeI18nObj(view.title, defaultLanguage, languages),
                                format: format,
                                extension: extension,
                                zipped: false,
                                criteria: newCriteria,
                                fullCriteria: view.criteria,
                                isFilterEnabled: dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && (
                                  getFilterCombinationCount(jsonStat, layoutObj.layout?.filters) > 1 ||
                                  (layoutObj.layout?.primaryDim?.[0] !== undefined &&
                                    layoutObj.layout.primaryDimValues.length < jsonStat.size[jsonStat.id.indexOf(layoutObj.layout.primaryDim[0])]) ||
                                  (layoutObj.layout?.secondaryDim?.[0] !== undefined &&
                                    layoutObj.layout.secondaryDimValues.length < jsonStat.size[jsonStat.id.indexOf(layoutObj.layout.secondaryDim[0])])
                                ),
                                layout: layoutObj.layout,
                                params: exportParams,
                                t: t
                              });
                            }
                          }}
                        >
                          {formats.map(({format, label, extension}, idx) =>
                            <div
                              key={idx}
                              data-value={{format, extension}}
                              aria-label={t("components.dashboard.actions.download.format.ariaLabel", {
                                datasetTitle: localizeI18nObj(view.title, defaultLanguage, languages),
                                formatLabel: label
                              })}
                            >
                              {label}
                            </div>
                          )}
                        </ButtonSelect>
                      )
                      : <span/>
                  })()}
                </div>
              );

              const StaticFiltersComponent = (
                <div
                  id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__static-filters`}
                  className={`${classes.viewContainerHeaderStaticFilters} dashboard__view-container__header__static-filters`}
                >
                  {staticFilters.map(({dim, dimLabel, value, valueLabel}, idx) =>
                    <Fragment key={idx}>
                      <b>{(dimLabel || dim)}</b>:
                      <i> {(valueLabel || value)}</i>
                      {dimAttributeMap?.[dim]?.[value] && (
                        <Tooltip
                          title={
                            <div>
                              {dimAttributeMap[dim][value].attributes
                                .map(({id, label, valueId, valueLabel}, idx) => (
                                  <div key={idx}>
                                    {`${label || id}: ${valueLabel || valueId}${valueLabel !== valueId ? ` [${valueId}]` : ''}`}
                                  </div>
                                ))
                              }
                            </div>
                          }
                          placement="top"
                        >
                          {(() => {
                            const ids = dimAttributeMap[dim][value].ids;
                            if (ids && ids.length === 1 && ids[0].length <= 2) {
                              return <span className={classes.attributeId}>{ids[0]}</span>
                            } else {
                              return <span className={classes.attributeAst}>(*)</span>
                            }
                          })()}
                        </Tooltip>
                      )}
                      <span style={{marginRight: 4}}>
                        {idx < staticFilters.length - 1 ? "," : ""}
                      </span>
                    </Fragment>
                  )}
                </div>
              );

              const ActiveFiltersComponent = (
                <div
                  id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__active-filters`}
                  className={`${classes.viewContainerHeaderActiveFilters} dashboard__view-container__header__active-filters`}
                >
                  <DatasetFilters
                    jsonStat={jsonStat}
                    layout={layout}
                    filterTree={filterTree}
                    onSelect={(dimension, value) => onSelect(dashboardId, viewIdx, dimension, value)}
                  />
                </div>
              );

              const PlaceHolderComponent = (
                <div
                  id={`dashboard__${dashboardId}__view-container__${viewIdx}__header__placeholder`}
                  className={`${classes.viewContainerHeaderPlaceholder} dashboard__view-container__header__pleaceholder`}
                />
              );

              return (
                <Fragment>
                  <div
                    id={`dashboard__${dashboardId}__view-container__${viewIdx}__header`}
                    className={`${classes.viewContainerHeader} dashboard__view-container__header`}
                  >
                    {dashboardElem[DASHBOARD_ELEM_SHOW_TITLE_KEY]
                      ? (
                        <Fragment>
                          {TitleComponent}
                          {ControllersComponent}
                          {staticFilters.length > 0 && StaticFiltersComponent}
                          {dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && filterTree && ActiveFiltersComponent}
                        </Fragment>
                      )
                      : staticFilters.length > 0 ?
                        (
                          <Fragment>
                            {StaticFiltersComponent}
                            {ControllersComponent}
                            {dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && filterTree && ActiveFiltersComponent}
                          </Fragment>
                        )
                        : dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY] && filterTree
                          ? (
                            <Fragment>
                              {ActiveFiltersComponent}
                              {ControllersComponent}
                            </Fragment>
                          )
                          : (
                            <Fragment>
                              {PlaceHolderComponent}
                              {ControllersComponent}
                            </Fragment>
                          )
                    }
                  </div>
                  <div
                    id={`dashboard__${dashboardId}__view-container__${viewIdx}__view`}
                    className={`${classes.viewContainerView} dashboard__view-container__view`}
                  >
                    {(() => {
                      const decimalSeparator = localizeI18nObj(view.decimalSeparator, defaultLanguage, languages);
                      const decimalPlaces = view.decimalNumber;

                      if (viewerIdx === 0) {
                        return (
                          <Table
                            jsonStat={jsonStat}
                            hiddenAttributes={nodeHiddenAttributes}
                            layout={layout}
                            labelFormat={layoutObj.labelFormat}
                            showTrend={layoutObj.showTrend}
                            showCyclical={layoutObj.showCyclical}
                            decimalSeparator={decimalSeparator}
                            decimalPlaces={decimalPlaces}
                            emptyChar={layoutObj.tableEmptyChar}
                            isFullscreen={isFullscreen}
                            hideSpinner
                            disableWheelZoom
                          />
                        )
                      } else if (viewerIdx === 1) {
                        return (
                          <Map
                            mapId={mapId}
                            jsonStat={jsonStat}
                            hiddenAttributes={nodeHiddenAttributes}
                            layout={layout}
                            labelFormat={layoutObj.labelFormat}
                            decimalSeparator={decimalSeparator}
                            decimalPlaces={decimalPlaces}
                            geometries={map?.geometries || null}
                            geometryDetailLevels={map?.geometryDetailLevels || null}
                            onGeometryFetch={idList => fetchMapGeometries(dashboardId, viewIdx, idList, t, view.nodeId)}
                            detailLevel={layoutObj.mapDetailLevel}
                            classificationMethod={layoutObj.mapSettings.mapClassificationMethod}
                            paletteStartColor={layoutObj.mapSettings.mapPaletteStartColor}
                            paletteEndColor={layoutObj.mapSettings.mapPaletteEndColor}
                            paletteCardinality={layoutObj.mapSettings.mapPaletteCardinality}
                            opacity={layoutObj.mapSettings.mapOpacity}
                            isLegendCollapsed={layoutObj.mapSettings.mapIsLegendCollapsed}
                            isFullscreen={isFullscreen}
                            readOnly
                            disableDetailLevelSelector={!dashboardElem[DASHBOARD_ELEM_ENABLE_FILTERS_KEY]}
                            hideSpinner
                            disableWheelZoom
                          />
                        )
                      } else {
                        return (
                          <Chart
                            chartId={chartId}
                            type={view.defaultView}
                            jsonStat={jsonStat}
                            hiddenAttributes={nodeHiddenAttributes}
                            layout={layout}
                            timePeriodsByFreq={timePeriodsByFreq}
                            labelFormat={layoutObj.labelFormat}
                            showTrend={layoutObj.showTrend}
                            showCyclical={layoutObj.showCyclical}
                            decimalSeparator={decimalSeparator}
                            decimalPlaces={decimalPlaces}
                            chartSettings={layoutObj.chartSettings}
                            disableWheelZoom
                          />
                        )
                      }
                    })()}
                  </div>

                  <Dialog
                    open={isAttributesVisible}
                    fullWidth
                    maxWidth="md"
                    onClose={() => setAttributesVisibility(false)}
                  >
                    <CustomDialogTitle onClose={() => setAttributesVisibility(false)}>
                      {t("components.dashboard.dialogs.attributes.title")}
                    </CustomDialogTitle>
                    <DialogContent>
                      <AttributeList
                        datasetAttributes={datasetAttributes}
                        seriesAttributes={seriesAttributes}
                        labelFormat={layoutObj.labelFormat}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setAttributesVisibility(false)}>
                        {t("commons.confirm.close")}
                      </Button>
                    </DialogActions>
                  </Dialog>

                </Fragment>
              )
            } else {
              return <span/>
            }
          } else {
            return <span/>
          }
        })()}
      </Card>
    </div>
  )
}

export default compose(
  withTranslation(),
  withStyles(styles),
  connect(state => ({
    hub: state.hub,
    defaultLanguage: state.app.language,
    languages: state.app.languages,
    minDashboardWidth: state.appConfig?.minDashboardWidth
  }))
)(DashboardCol);
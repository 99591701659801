import React, {Fragment, useEffect, useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {useTranslation} from "react-i18next";
import DataViewerHeader from "./Header";
import DataViewerSideBar from "./Sidebar";
import DataViewerFooter from "./Footer";
import DatasetFilters from "../dataset-filters";
import Table, {JSONSTAT_TABLE_FONT_SIZE_LG, JSONSTAT_TABLE_FONT_SIZE_MD, JSONSTAT_TABLE_FONT_SIZE_SM} from "../table";
import Criteria from "../criteria";
import TableLayout from "../table-layout";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomDialogTitle from "../custom-dialog-title";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import FontSizeSelector, {FONT_SIZE_SELECTOR_FONT_SIZE_MD, FONT_SIZE_SELECTOR_FONT_SIZE_SM} from "../font-size-selector";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import CustomEmpty from "../custom-empty";
import TableChartIcon from "@material-ui/icons/TableChart";
import MapIcon from "@material-ui/icons/Map";
import BarChartIcon from "@material-ui/icons/BarChart";
import TimelineIcon from "@material-ui/icons/Timeline";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import PieChartIcon from "@material-ui/icons/PieChart";
import AreaIcon from "../custom-icons/AreaIcon";
import RadarIcon from "../custom-icons/RadarIcon";
import PolarIcon from "../custom-icons/PolarIcon";
import PyramidIcon from "../custom-icons/PyramindIcon";
import {compose} from "redux";
import {connect} from "react-redux";
import {
  enableDatasetFetch,
  fetchDataset,
  fetchDatasetGeometries,
  hideDatasetCriteria,
  hideDatasetCriteriaAlert,
  hideDatasetDownloadWarning,
  hideDatasetUnavailableViewWarning,
  setDatasetChartSettings,
  setDatasetLabelFormat,
  setDatasetMapDetailLevel,
  setDatasetMapSettings,
  setDatasetVariation,
  setDatasetViewer,
  showDatasetCriteria,
  submitDatasetChartFilterTree,
  submitDatasetChartLayout,
  submitDatasetDownload,
  submitDatasetMapLayout,
  submitDatasetTableFilterTree,
  submitDatasetTableLayout,
  submitDatasetViewTemplate
} from "../../state/dataset/datasetActions";
import Alert from "@material-ui/lab/Alert";
import {
  getDimensionFilterValues,
  getFilterCombinationCount,
  getFilterTreeFromJsonStat,
  getInitialFiltersValue,
  getUpdatedLayout,
  TIME_PERIOD_DIMENSION_KEY
} from "../../utils/jsonStat";
import {numberFormatter} from "../../utils/formatters";
import Call from "../../hocs/call";
import ChartLayout from "../chart-layout";
import {v4 as uuidv4} from 'uuid';
import Map, {MAP_PALETTE_COLOR_END_DEFAULT, MAP_PALETTE_COLOR_START_DEFAULT} from "../map/index";
import LabelFormatSelector from "../label-format-selector";
import Chart, {
  CHART_TYPE_AREA,
  CHART_TYPE_BAR,
  CHART_TYPE_DOUGHNUT,
  CHART_TYPE_HORIZONTAL_BAR,
  CHART_TYPE_LINE,
  CHART_TYPE_PIE,
  CHART_TYPE_POLAR_AREA,
  CHART_TYPE_PYRAMID,
  CHART_TYPE_RADAR
} from "../chart";
import SettingsIcon from "@material-ui/icons/Settings";
import ChartSettings from "../chart-settings";
import colorString from "color-string";
import MapSettings from "../map-settings";
import VariationSelector from "../variation-selector";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";
import {DOWNLOAD_FORMAT_CSV, DOWNLOAD_FORMAT_EXCEL, getDownloadFormatExtensionFromFormat} from "../../utils/download";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getTextWidth} from "../../utils/style";
import {getCustomCssUrl} from "../../serverApi/urls";
import {toggleFullScreen} from "../../utils/other";
import GetAppIcon from '@material-ui/icons/GetApp';
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";

const $ = window.jQuery;

const SIDEBAR_WIDTH = 80;

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  },
  header: {
    width: "100%"
  },
  page: {
    width: "100%",
    minHeight: 400,
    minWidth: 560
  },
  sidebar: {
    display: "inline-block",
    verticalAlign: "top",
    width: SIDEBAR_WIDTH,
    height: "100%",
    overflow: "auto",
    marginLeft: theme.spacing(2),
    padding: "0 8px"
  },
  data: {
    display: "inline-block",
    verticalAlign: "top",
    width: `calc(100% - ${SIDEBAR_WIDTH + theme.spacing(2) + theme.spacing(2) + theme.spacing(2)}px)`,
    height: "100%",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(2)
  },
  viewerContainer: {
    width: "100%",
    height: "100%"
  },
  filtersAndActions: {
    width: "100%",
    paddingBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  filters: {},
  actions: {
    marginBottom: 8
  },
  action: {
    height: 40,
    "& button": {
      padding: 8
    }
  },
  viewer: {
    width: "100%",
    position: "relative"
  },
  attributes: {
    width: "100%",
    paddingTop: 12
  },
  footer: {
    bottom: 0,
    left: 0,
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    padding: theme.spacing(1)
  },
  warningAlert: {
    width: "100%",
    marginBottom: 16
  },
  criteriaContent: {
    overflow: "hidden !important"
  },
  exportLabel: {
    padding: "12px 12px 12px 0"
  },
  alert: {
    marginBottom: 12
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  fieldLastRow: {
    marginBottom: 0
  }
});

const mapStateToProps = ({hub, dataset}) => ({
  exportConfig: hub.hub.exportConfig,
  dataset: dataset.dataset,
  isFetchDatasetDisabled: dataset.isFetchDatasetDisabled,
  isCriteriaVisible: dataset.isCriteriaVisible,
  isCriteriaAlertVisible: dataset.isCriteriaAlertVisible,
  hasViewLayout: dataset.hasViewLayout,
  hasTemplateLayout: dataset.hasTemplateLayout,
  hasAnnotationLayout: dataset.hasAnnotationLayout,
  dimensions: dataset.dimensions,
  freqDimension: dataset.freqDimension,
  mode: dataset.mode,
  type: dataset.type,
  codelistsLength: dataset.codelistsLength,
  viewerIdx: dataset.viewerIdx,
  tableLayout: dataset.tableLayout,
  mapLayout: dataset.mapLayout,
  chartLayout: dataset.chartLayout,
  tableFilterTree: dataset.tableFilterTree,
  mapFilterTree: dataset.mapFilterTree,
  chartFilterTree: dataset.chartFilterTree,
  timePeriodsByFreq: dataset.timePeriodsByFreq,
  labelFormat: dataset.labelFormat,
  showTrend: dataset.showTrend,
  showCyclical: dataset.showCyclical,
  criteria: dataset.criteria,
  decimalSeparator: dataset.decimalSeparator,
  decimalPlaces: dataset.decimalPlaces,
  tableEmptyChar: dataset.tableEmptyChar,
  chartSettings: dataset.chartSettings,
  mapDetailLevel: dataset.mapDetailLevel,
  mapSettings: dataset.mapSettings,
  hasMapStartColor: dataset.hasMapStartColor,
  hasMapEndColor: dataset.hasMapEndColor,
  enableCriteria: dataset.enableCriteria,
  enableLayout: dataset.enableLayout,
  enableVariation: dataset.enableVariation,
  codes: dataset.codes,
  isCodesFlat: dataset.isCodesFlat,
  codelists: dataset.codelists,
  areCodelistsFlat: dataset.areCodelistsFlat,
  timePeriod: dataset.timePeriod,
  criteriaObsCount: dataset.criteriaObsCount,
  geometries: dataset.geometries,
  geometryDetailLevels: dataset.geometryDetailLevels,
  isPartialData: dataset.isPartialData,
  isEmptyData: dataset.isEmptyData,
  isTooBigData: dataset.isTooBigData,
  isTooLongQuery: dataset.isTooLongQuery,
  isDownloadWarningVisible: dataset.isDownloadWarningVisible,
  isUnavailableViewWarningVisible: dataset.isUnavailableViewWarningVisible,
  timings: dataset.timings
});

const mapDispatchToProps = dispatch => ({
  onDatasetFetch: ({nodeId, datasetId, criteria, datasetTitle}) => dispatch(fetchDataset(nodeId, datasetId, criteria, datasetTitle)),
  onFetchDatasetEnable: () => dispatch(enableDatasetFetch()),
  onCriteriaShow: () => dispatch(showDatasetCriteria()),
  onCriteriaHide: () => dispatch(hideDatasetCriteria()),
  onCriteriaAlertHide: () => dispatch(hideDatasetCriteriaAlert()),
  onViewerSet: viewerIdx => dispatch(setDatasetViewer(viewerIdx)),
  onTableLayoutSet: layout => dispatch(submitDatasetTableLayout(layout)),
  onTableFilterTreeSet: filterTree => dispatch(submitDatasetTableFilterTree(filterTree)),
  onMapLayoutSet: layout => dispatch(submitDatasetMapLayout(layout)),
  onChartLayoutSet: layout => dispatch(submitDatasetChartLayout(layout)),
  onChartFilterTreeSet: filterTree => dispatch(submitDatasetChartFilterTree(filterTree)),
  onLabelFormatSet: labelFormat => dispatch(setDatasetLabelFormat(labelFormat)),
  onVariationSet: variation => dispatch(setDatasetVariation(variation)),
  onChartSettingsSet: chartSetting => dispatch(setDatasetChartSettings(chartSetting)),
  onMapDetailLevelSet: detailLevel => dispatch(setDatasetMapDetailLevel(detailLevel)),
  onMapSettingsSet: mapSettings => dispatch(setDatasetMapSettings(mapSettings)),
  onDownloadSubmit: (nodeId, datasetId, datasetTitle, format, extension, zipped, criteria, layout, params, t) =>
    dispatch(submitDatasetDownload(nodeId, datasetId, datasetTitle, format, extension, zipped, criteria, layout, params, t)),
  onDownloadHide: () => dispatch(hideDatasetDownloadWarning()),
  onViewTemplateSubmit: (nodeId, viewTemplate, isView) => dispatch(submitDatasetViewTemplate(nodeId, viewTemplate, isView)),
  onUnavailableViewHide: () => dispatch(hideDatasetUnavailableViewWarning()),
  onGeometryFetch: (idList, t, nodeId) => dispatch(fetchDatasetGeometries(idList, t, nodeId))
});

const viewers = t => [
  {
    key: 0,
    type: "table",
    title: t ? t("scenes.dataViewers.viewers.table") : "",
    icon: <TableChartIcon/>
  },
  {
    key: 1,
    title: t ? t("scenes.dataViewers.viewers.map") : "",
    type: "map",
    icon: <MapIcon/>
  },
  {
    key: 2,
    title: t ? t("scenes.dataViewers.viewers.verticalBar") : "",
    type: CHART_TYPE_BAR,
    icon: <BarChartIcon/>,
    chartType: "bar"
  },
  {
    key: 3,
    title: t ? t("scenes.dataViewers.viewers.horizontalBar") : "",
    type: CHART_TYPE_HORIZONTAL_BAR,
    icon: <BarChartIcon style={{transform: "rotate(90deg)"}}/>,
    chartType: "horizontalBar"
  },
  {
    key: 4,
    title: t ? t("scenes.dataViewers.viewers.line") : "",
    type: CHART_TYPE_LINE,
    icon: <TimelineIcon/>,
    chartType: "line"
  },
  {
    key: 5,
    title: t ? t("scenes.dataViewers.viewers.area") : "",
    type: CHART_TYPE_AREA,
    icon: <AreaIcon/>,
    chartType: "area"
  },
  {
    key: 6,
    title: t ? t("scenes.dataViewers.viewers.doughnut") : "",
    type: CHART_TYPE_DOUGHNUT,
    icon: <DonutLargeIcon/>,
    chartType: "doughnut"
  },
  {
    key: 7,
    title: t ? t("scenes.dataViewers.viewers.pie") : "",
    type: CHART_TYPE_PIE,
    icon: <PieChartIcon/>,
    chartType: "pie"
  },
  {
    key: 8,
    title: t ? t("scenes.dataViewers.viewers.radar") : "",
    type: CHART_TYPE_RADAR,
    icon: <RadarIcon/>,
    chartType: "radar"
  },
  {
    key: 9,
    title: t ? t("scenes.dataViewers.viewers.polarArea") : "",
    type: CHART_TYPE_POLAR_AREA,
    icon: <PolarIcon/>,
    chartType: "polarArea"
  },
  {
    key: 10,
    title: t ? t("scenes.dataViewers.viewers.pyramid") : "",
    type: CHART_TYPE_PYRAMID,
    icon: <PyramidIcon/>,
    chartType: "pyramid"
  }
];

export const getViewerIdxFromType = viewerType => {
  if (!viewerType) {
    return 0
  }

  const viewer = viewers().find(({type}) => type === viewerType);
  return viewer
    ? viewer.key
    : 0
};

export const getViewerTypeFromIdx = viewerIdx => viewers()[viewerIdx].type;

const handleStyle = () => {

  const hubFooterHeight = $("#footer").outerHeight(true) || 0;
  $("#data-viewer").css({height: `calc(100% - ${hubFooterHeight}px)`});

  const headerHeight = $("#data-viewer__header").outerHeight(true) || 0;
  const footerHeight = $("#data-viewer__footer").outerHeight(true) || 0;
  $("#data-viewer__page").css({height: `calc(100% - ${headerHeight + footerHeight}px)`});

  const $actions = $("#data-viewer__page__viewer__actions");
  const $filters = $("#data-viewer__page__viewer__filters");
  const $filtersAndActions = $("#data-viewer__page__viewer__filters-and-actions");

  const actionsWidth = $actions.outerWidth(true) || 0;
  const filtersWidth = $filters.outerWidth(true) || 0;
  const filtersAndActionsWidth = $filtersAndActions.width() || 0;

  if (filtersAndActionsWidth > (actionsWidth + filtersWidth)) {
    $filtersAndActions.css({flexDirection: "row"});
    $actions.css({alignSelf: "unset"});

  } else {
    $filtersAndActions.css({flexDirection: "column-reverse"});
    $actions.css({alignSelf: "end"});
  }

  const viewerHeaderHeight = $("#data-viewer__page__viewer__header").outerHeight(true) || 0;
  $("#data-viewer__page__viewer__viewer").css({
    height: `calc(100% - ${viewerHeaderHeight}px)`
  });
};

const COLS_SEPARATOR_COMMA = ",";
const COLS_SEPARATOR_SEMICOLON = ";";
const COLS_SEPARATOR_PIPE = "|";
const COLS_SEPARATOR_TAB = "\t";

const getColsSeparators = t => ([
  {
    value: COLS_SEPARATOR_COMMA,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.comma") : "Comma (,)"
  },
  {
    value: COLS_SEPARATOR_SEMICOLON,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.semicolon") : "Semicolon (;)"
  },
  {
    value: COLS_SEPARATOR_PIPE,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.pipe") : "Pipe (|)"
  },
  {
    value: COLS_SEPARATOR_TAB,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.tab") : "Tab ( )"
  }
]);

const getInitialCsvParams = csvSeparator => ({
  labelFormat: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  colsSeparator: (csvSeparator && getColsSeparators().map(({value}) => value).includes(csvSeparator))
    ? csvSeparator
    : COLS_SEPARATOR_COMMA,
  textQualifier: ""
});

function DataViewer(props) {
  const {
    classes,

    nodeId,
    nodeCode,
    categoryPath,
    datasetId,
    datasetTitle,
    viewId,
    notes,
    attachedFiles,
    referenceMetadataUrl,
    nodeExtras,
    maxObservation,

    exportConfig,
    dataset,
    isFetchDatasetDisabled,
    isCriteriaVisible,
    isCriteriaAlertVisible,
    hasViewLayout,
    hasTemplateLayout,
    hasAnnotationLayout,
    dimensions,
    freqDimension,
    mode,
    type,
    codelistsLength,
    viewerIdx,
    tableLayout,
    mapLayout,
    chartLayout,
    tableFilterTree,
    mapFilterTree,
    chartFilterTree,
    timePeriodsByFreq,
    labelFormat,
    showTrend,
    showCyclical,
    criteria,
    decimalSeparator,
    decimalPlaces,
    tableEmptyChar,
    chartSettings,
    mapDetailLevel,
    mapSettings,
    hasMapStartColor,
    hasMapEndColor,
    enableCriteria,
    enableLayout,
    enableVariation,
    codes,
    isCodesFlat,
    codelists,
    areCodelistsFlat,
    timePeriod,
    criteriaObsCount,
    geometries,
    geometryDetailLevels,
    isPartialData,
    isEmptyData,
    isTooBigData,
    isTooLongQuery,
    isDownloadWarningVisible,
    isUnavailableViewWarningVisible,
    timings,

    onDatasetFetch,
    onFetchDatasetEnable,
    onCriteriaShow,
    onCriteriaHide,
    onCriteriaAlertHide,
    onViewerSet,
    onTableLayoutSet,
    onTableFilterTreeSet,
    onMapLayoutSet,
    onChartLayoutSet,
    onChartFilterTreeSet,
    onLabelFormatSet,
    onVariationSet,
    onChartSettingsSet,
    onMapDetailLevelSet,
    onMapSettingsSet,
    onDownloadSubmit,
    onDownloadHide,
    onViewTemplateSubmit,
    onUnavailableViewHide,
    onGeometryFetch
  } = props;

  const {t} = useTranslation();

  const [tmpTableLayout, setTmpTableLayout] = useState(null);
  const [tmpChartLayout, setTmpChartLayout] = useState(null);

  const [chartId] = useState("chart__" + uuidv4());

  const [mapId] = useState("map__" + uuidv4());

  const [isLayoutVisible, setLayoutVisibility] = useState(false);
  const [isCriteriaValid, setCriteriaValidity] = useState(true);

  const [isFullscreen, setFullscreen] = useState(false);
  const [fontSize, setFontSize] = useState(FONT_SIZE_SELECTOR_FONT_SIZE_MD);

  const [isChartSettingsVisible, setChartSettingsVisibility] = useState(false);
  const [tmpChartSettings, setTmpChartSettings] = useState(null);

  const [isMapSettingsVisible, setMapSettingsVisibility] = useState(false);
  const [tmpMapSettings, setTmpMapSettings] = useState(null);

  const [isObsCountWarningVisible, setIsObsCountWarningVisible] = useState(false);

  const [exportFormat, setExportFormat] = useState(null);
  const [exportParams, setExportParams] = useState(null);
  const [singleSheet, setSingleSheet] = useState(true);
  const [filteredDataset, setFilteredDataset] = useState(true);
  const [csvParams, setCsvParams] = useState(getInitialCsvParams(exportConfig?.csvSeparator));

  useEffect(() => {
    window.addEventListener("resize", handleStyle);
    return () => window.removeEventListener("resize", handleStyle)
  }, []);

  useEffect(() => {
    handleStyle();
  }, [dataset, viewerIdx, tableLayout, mapLayout, chartLayout, labelFormat]);

  useEffect(() => {
    setTmpTableLayout(tableLayout);
  }, [tableLayout]);

  useEffect(() => {
    setTmpChartLayout(chartLayout);
  }, [chartLayout]);

  useEffect(() => {
    setTmpChartSettings(chartSettings);
  }, [chartSettings]);

  useEffect(() => {
    setTmpMapSettings(mapSettings);
  }, [mapSettings]);

  // handling customCss map customization
  useEffect(() => {
    if (hasMapStartColor === false || hasMapEndColor === false) {

      const CUSTOM_CSS_MAP_SELECTOR_PREFIX = ".map__";
      const CUSTOM_CSS_MAP_SELECTOR_NODE_PREFIX = ".map__node__"

      const CUSTOM_CSS_MAP_SELECTOR_COLOR_START = "start-color"
      const CUSTOM_CSS_MAP_SELECTOR_COLOR_END = "end-color"

      fetch(getCustomCssUrl())
        .then(response => response.text())
        .then(css => {
          const doc = document.implementation.createHTMLDocument("");
          const styleEl = document.createElement("style");
          styleEl.textContent = String(css);
          doc.body.appendChild(styleEl);

          const colorRules = {};
          Object.values(styleEl.sheet.cssRules)
            .filter(({selectorText, style}) =>
              selectorText !== null &&
              selectorText !== undefined &&
              selectorText.startsWith(CUSTOM_CSS_MAP_SELECTOR_PREFIX) &&
              style[0] === "color" &&
              style.color.length > 0 &&
              colorString.get.rgb(style.color) !== null
            )
            .forEach(({selectorText, style}) => {
              colorRules[selectorText] = colorString.to.hex(colorString.get.rgb(style.color));
            });

          let start = null;
          if (!hasMapStartColor) {
            start = (nodeCode && colorRules[CUSTOM_CSS_MAP_SELECTOR_NODE_PREFIX + nodeCode + "__" + CUSTOM_CSS_MAP_SELECTOR_COLOR_START]) ||
              colorRules[CUSTOM_CSS_MAP_SELECTOR_PREFIX + CUSTOM_CSS_MAP_SELECTOR_COLOR_START] ||
              MAP_PALETTE_COLOR_START_DEFAULT;
          }

          let end = null;
          if (!hasMapEndColor) {
            end = (nodeCode && colorRules[CUSTOM_CSS_MAP_SELECTOR_NODE_PREFIX + nodeCode + "__" + CUSTOM_CSS_MAP_SELECTOR_COLOR_END]) ||
              colorRules[CUSTOM_CSS_MAP_SELECTOR_PREFIX + CUSTOM_CSS_MAP_SELECTOR_COLOR_END] ||
              MAP_PALETTE_COLOR_END_DEFAULT;
          }

          onMapSettingsSet({
            mapPaletteStartColor: start,
            mapPaletteEndColor: end
          });
        });
    }
  }, [nodeCode, hasMapStartColor, hasMapEndColor, onMapSettingsSet]);

  const handleSetViewer = viewerIdx => {
    onViewerSet(viewerIdx);
  };

  const handleLayoutOpen = () => {
    setLayoutVisibility(true);
  };

  const handleLayoutClose = () => {
    setLayoutVisibility(false);
    setTmpTableLayout(tableLayout);
    setTmpChartLayout(chartLayout);
  };

  const handleLayoutSubmit = () => {
    setLayoutVisibility(false);

    if (viewerIdx === 0) {
      const newTableFilterTree = getFilterTreeFromJsonStat(tmpTableLayout, dataset);
      onTableLayoutSet({
        ...tmpTableLayout,
        filtersValue: getInitialFiltersValue(dataset, tmpTableLayout, newTableFilterTree)
      });
      onTableFilterTreeSet(newTableFilterTree);

    } else if (viewerIdx >= 2) {
      const newPrimaryDim = tmpChartLayout.primaryDim[0];
      const newSecondaryDim = tmpChartLayout.secondaryDim[0];
      const prevPrimaryDim = chartLayout.primaryDim[0];
      const prevSecondaryDim = chartLayout.secondaryDim[0];

      const isSecondaryDimChanged = (
        (!prevSecondaryDim && !!newSecondaryDim) ||
        (!!prevSecondaryDim && !newSecondaryDim) ||
        (!!prevSecondaryDim && !!newSecondaryDim && prevSecondaryDim !== newSecondaryDim)
      );

      const newChartFilterTree = getFilterTreeFromJsonStat(tmpChartLayout, dataset);
      if (newPrimaryDim !== prevPrimaryDim) {
        tmpChartLayout.primaryDimValues = dataset.dimension[newPrimaryDim].category.index;
        tmpChartLayout.secondaryDimValues = newSecondaryDim
          ? getDimensionFilterValues(newSecondaryDim, dataset, tmpChartLayout, newChartFilterTree)
          : []
      } else if (isSecondaryDimChanged) {
        tmpChartLayout.secondaryDimValues = newSecondaryDim
          ? getDimensionFilterValues(newSecondaryDim, dataset, tmpChartLayout, newChartFilterTree)
          : []
      }
      onChartLayoutSet({
        ...tmpChartLayout,
        filtersValue: getInitialFiltersValue(dataset, tmpChartLayout, newChartFilterTree)
      });
      onChartFilterTreeSet(newChartFilterTree);
    }
  };

  const handleCriteriaOpen = () => {
    onCriteriaShow();
  }

  const handleCriteriaClose = () => {
    onCriteriaHide();
    setCriteriaValidity(true);
  }

  const handleCriteriaSubmit = () => {
    onFetchDatasetEnable();
  }

  const handleFilterSelect = (dimension, value) => {
    if (viewerIdx === 0) {
      onTableLayoutSet(getUpdatedLayout(dimension, value, dataset, tableLayout, tableFilterTree));
    } else if (viewerIdx === 1) {
      onMapLayoutSet(getUpdatedLayout(dimension, value, dataset, mapLayout, mapFilterTree));
    } else if (viewerIdx >= 2) {
      onChartLayoutSet(getUpdatedLayout(dimension, value, dataset, chartLayout, chartFilterTree));
    }
  };

  const handleFontSizeSelect = size => {
    setFontSize(size);
  };

  const handleChartSettingsOpen = () => {
    setChartSettingsVisibility(true);
  };

  const handleChartSettingsClose = () => {
    setChartSettingsVisibility(false);
    setTmpChartSettings(chartSettings);
  };

  const handleChartSettingsSubmit = () => {
    setChartSettingsVisibility(false);
    onChartSettingsSet(tmpChartSettings);
  };

  const handleMapSettingsOpen = () => {
    setMapSettingsVisibility(true);
  };

  const handleMapSettingsClose = () => {
    setMapSettingsVisibility(false);
    setTmpMapSettings(mapSettings);
  };

  const handleMapSettingsSubmit = () => {
    setMapSettingsVisibility(false);
    onMapSettingsSet(tmpMapSettings);
  };

  const handleFullscreen = () => {
    toggleFullScreen("data-viewer__page__viewer", "data-viewer__page__viewer__wrapper", !isFullscreen);
    handleStyle();
    setFullscreen(!isFullscreen);
  };

  const handleExportExcelShow = params => {
    setExportFormat(DOWNLOAD_FORMAT_EXCEL);
    setSingleSheet(true);
    setExportParams(params);
  };

  const handleExportExcelHide = () => {
    setExportFormat(null);
    setExportParams(null);
  };

  const handleExportExcelSubmit = () => {
    handleExportExcelHide();
    onDownloadSubmit(
      nodeId,
      datasetId,
      datasetTitle,
      DOWNLOAD_FORMAT_EXCEL,
      getDownloadFormatExtensionFromFormat(DOWNLOAD_FORMAT_EXCEL),
      false,
      criteria,
      tableLayout,
      {
        ...exportParams,
        ignoreFilters: !singleSheet
      },
      t
    );
  };

  const handleExportCsvShow = params => {
    setExportFormat(DOWNLOAD_FORMAT_CSV);
    setFilteredDataset(true);
    setExportParams(params);
    setCsvParams(getInitialCsvParams(exportConfig?.csvSeparator));
  };

  const handleExportCsvHide = () => {
    setExportFormat(null);
    setExportParams(null);
  };

  const handleExportCsvSubmit = () => {
    const exportLayout = viewerIdx === 0
      ? tableLayout
      : viewerIdx === 1
        ? mapLayout
        : chartLayout;

    handleExportCsvHide();
    onDownloadSubmit(
      nodeId,
      datasetId,
      datasetTitle,
      DOWNLOAD_FORMAT_CSV,
      getDownloadFormatExtensionFromFormat(DOWNLOAD_FORMAT_CSV),
      false,
      criteria,
      exportLayout,
      {
        ...exportParams,
        ignoreFilters: !filteredDataset,
        ...csvParams
      },
      t
    );
  };

  return (
    <div id="data-viewer" className={classes.root}>
      <Call
        cb={onDatasetFetch}
        cbParam={{
          nodeId,
          datasetId,
          criteria,
          datasetTitle
        }}
        disabled={!nodeId || !datasetId || !criteria || !datasetTitle || isFetchDatasetDisabled}
      >
        <div id="data-viewer__header" className={classes.header}>
          <DataViewerHeader
            nodeId={nodeId}
            nodeDownloadFormats={nodeExtras?.DownloadFormats}
            hiddenAttributes={nodeExtras?.HiddenAttributes}
            showQueryInfo={nodeExtras?.QueryInfo}
            datasetId={datasetId}
            datasetTitle={datasetTitle}
            viewId={viewId}
            hasViewLayout={hasViewLayout}
            hasTemplateLayout={hasTemplateLayout}
            hasAnnotationLayout={hasAnnotationLayout}
            notes={notes}
            attachedFiles={attachedFiles}
            jsonStat={dataset}
            timePeriodsByFreq={timePeriodsByFreq}
            tableLayout={tableLayout}
            mapLayout={mapLayout}
            chartLayout={chartLayout}
            labelFormat={labelFormat}
            showTrend={showTrend}
            showCyclical={showCyclical}
            criteria={criteria}
            decimalSeparator={decimalSeparator}
            decimalPlaces={decimalPlaces}
            tableEmptyChar={tableEmptyChar}
            chartSettings={chartSettings}
            mapDetailLevel={mapDetailLevel}
            mapSettings={mapSettings}
            viewers={viewers(t)}
            viewerIdx={viewerIdx}
            onViewTemplateSubmit={(viewTemplate, isView) => onViewTemplateSubmit(nodeId, viewTemplate, isView)}
            onDownloadSubmit={(format, extension) => {
              const exportParams = {
                decimalNumber: decimalPlaces,
                decimalSeparator: decimalSeparator,
                emptyCellPlaceHolder: tableEmptyChar,
                labelFormat: labelFormat,
                customLabelFormat: {
                  [TIME_PERIOD_DIMENSION_KEY]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                },
                hasVariation: enableVariation,
                showTrend: showTrend,
                showCyclical: showCyclical,
                exportConfig: exportConfig
              };

              if (format === DOWNLOAD_FORMAT_EXCEL && getFilterCombinationCount(dataset, tableLayout?.filters) > 1) {
                handleExportExcelShow(exportParams);
              } else if (format === DOWNLOAD_FORMAT_CSV) {
                handleExportCsvShow(exportParams);
              } else {
                onDownloadSubmit(nodeId, datasetId, datasetTitle, format, extension, false, criteria, tableLayout, exportParams, t);
              }
            }}
            timings={timings}
            chartId={chartId}
            mapId={mapId}
          />
        </div>
        <div id="data-viewer__page" className={classes.page}>
          <Card className={classes.sidebar}>
            <DataViewerSideBar
              viewers={viewers(t)}
              selected={viewerIdx}
              isCriteriaDisabled={!enableCriteria}
              isLayoutDisabled={!enableLayout || !dataset || !(!!tableLayout || !!mapLayout || !!chartLayout)}
              isTableDisabled={!dataset || !tableLayout}
              isChartDisabled={!dataset || !chartLayout}
              isMapDisabled={!dataset || !mapLayout}
              referenceMetadataUrl={referenceMetadataUrl}
              onViewerSelect={handleSetViewer}
              onCriteriaOpen={handleCriteriaOpen}
              onLayoutOpen={handleLayoutOpen}
            />
          </Card>
          <Card id="data-viewer__page__viewer__wrapper" className={classes.data}>
            <div id="data-viewer__page__viewer" className={classes.viewerContainer}>
              <div id="data-viewer__page__viewer__header">
                {dataset && (
                  <Fragment>
                    {dataset && isPartialData && (
                      <Alert severity="warning" className={classes.warningAlert}>
                        {t("scenes.dataViewer.warnings.maxObservation.label", {maxObservation: maxObservation ? numberFormatter(maxObservation) : ""})}
                      </Alert>
                    )}
                    <div id="data-viewer__page__viewer__filters-and-actions" className={classes.filtersAndActions}>
                      <div id="data-viewer__page__viewer__filters" className={classes.filters}>
                        <DatasetFilters
                          jsonStat={dataset}
                          hiddenAttributes={nodeExtras?.HiddenAttributes}
                          layout={viewerIdx === 0
                            ? tableLayout
                            : viewerIdx === 1
                              ? mapLayout
                              : chartLayout
                          }
                          filterTree={viewerIdx === 0
                            ? tableFilterTree
                            : viewerIdx === 1
                              ? mapFilterTree
                              : chartFilterTree
                          }
                          labelFormat={labelFormat}
                          onSelect={handleFilterSelect}
                        />
                      </div>
                      <div id="data-viewer__page__viewer__actions" className={classes.actions}>
                        <Grid container>
                          {enableVariation && (
                            <Grid item className={classes.action} id="variation-select">
                              <VariationSelector
                                showTrend={showTrend}
                                showCyclical={showCyclical}
                                onVariationSet={onVariationSet}
                              />
                            </Grid>
                          )}
                          <Grid item className={classes.action} id="label-format-select">
                            <LabelFormatSelector
                              labelFormat={labelFormat}
                              setLabelFormat={onLabelFormatSet}
                            />
                          </Grid>
                          <Grid item className={classes.action} id="export-csv">
                            <Tooltip title={t("scenes.dataViewer.actions.exportCsv.tooltip")}>
                              <IconButton
                                aria-label={t("scenes.dataViewer.actions.exportCsv.ariaLabel")}
                                onClick={() => {
                                  const exportParams = {
                                    decimalNumber: decimalPlaces,
                                    decimalSeparator: decimalSeparator,
                                    emptyCellPlaceHolder: tableEmptyChar,
                                    labelFormat: labelFormat,
                                    customLabelFormat: {
                                      [TIME_PERIOD_DIMENSION_KEY]: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID
                                    },
                                    hasVariation: enableVariation,
                                    showTrend: showTrend,
                                    showCyclical: showCyclical
                                  };
                                  const layout = viewerIdx === 0
                                    ? tableLayout
                                    : viewerIdx === 1
                                      ? mapLayout
                                      : chartLayout;

                                  onDownloadSubmit(
                                    nodeId,
                                    datasetId,
                                    datasetTitle,
                                    DOWNLOAD_FORMAT_CSV,
                                    getDownloadFormatExtensionFromFormat(DOWNLOAD_FORMAT_CSV),
                                    false,
                                    criteria,
                                    layout,
                                    exportParams,
                                    t
                                  );
                                }}
                              >
                                <GetAppIcon/>
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          {viewerIdx >= 2 && (
                            <Grid item className={classes.action} id="chart-settings">
                              <Tooltip title={t("scenes.dataViewer.actions.chartSettings.tooltip")}>
                                <IconButton
                                  aria-label={t("scenes.dataViewer.actions.chartSettings.ariaLabel")}
                                  onClick={handleChartSettingsOpen}
                                >
                                  <SettingsIcon/>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {viewerIdx === 1 && (
                            <Grid item className={classes.action} id="map-settings">
                              <Tooltip title={t("scenes.dataViewer.actions.mapSettings.tooltip")}>
                                <IconButton
                                  aria-label={t("scenes.dataViewer.actions.mapSettings.ariaLabel")}
                                  onClick={handleMapSettingsOpen}
                                >
                                  <SettingsIcon/>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                          {viewerIdx === 0 && (
                            <Grid item className={classes.action} id="font-size-select">
                              <FontSizeSelector
                                fontSize={fontSize}
                                setFontSize={handleFontSizeSelect}
                              />
                            </Grid>
                          )}
                          <Grid item className={classes.action} id="fullscreen">
                            <Tooltip
                              title={isFullscreen
                                ? t("scenes.dataViewer.actions.fullscreen.exit")
                                : t("scenes.dataViewer.actions.fullscreen.enter")
                              }
                            >
                              <IconButton onClick={handleFullscreen}>
                                {isFullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
              <div id="data-viewer__page__viewer__viewer" className={classes.viewer}>
                {(() => {
                  if (!dataset) {
                    return isEmptyData
                      ? <CustomEmpty text={t("scenes.dataViewer.errors.emptyData")}/>
                      : <CustomEmpty text={t("scenes.dataViewer.errors.applyCriteria")}/>

                  } else {
                    if (viewerIdx === 0 && tableLayout) {
                      return !isTooBigData
                        ? (
                          <Table
                            jsonStat={dataset}
                            hiddenAttributes={nodeExtras?.HiddenAttributes}
                            layout={tableLayout}
                            isFullscreen={isFullscreen}
                            labelFormat={labelFormat}
                            showTrend={showTrend}
                            showCyclical={showCyclical}
                            fontSize={fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_SM
                              ? JSONSTAT_TABLE_FONT_SIZE_SM
                              : fontSize === FONT_SIZE_SELECTOR_FONT_SIZE_MD
                                ? JSONSTAT_TABLE_FONT_SIZE_MD
                                : JSONSTAT_TABLE_FONT_SIZE_LG
                            }
                            decimalSeparator={decimalSeparator}
                            decimalPlaces={decimalPlaces}
                            emptyChar={tableEmptyChar}
                          />
                        )
                        : (
                          <CustomEmpty
                            text={t("scenes.dataViewer.errors.tooBigData")}
                          />
                        )

                    } else if (viewerIdx === 1 && mapLayout) {
                      return (
                        <Map
                          mapId={mapId}
                          jsonStat={dataset}
                          hiddenAttributes={nodeExtras?.HiddenAttributes}
                          layout={mapLayout}
                          labelFormat={labelFormat}
                          decimalSeparator={decimalSeparator}
                          decimalPlaces={decimalPlaces}
                          geometries={geometries}
                          geometryDetailLevels={geometryDetailLevels}
                          onGeometryFetch={idList => onGeometryFetch(idList, t, nodeId)}
                          detailLevel={mapDetailLevel}
                          setDetailLevel={onMapDetailLevelSet}
                          classificationMethod={mapSettings.mapClassificationMethod}
                          paletteStartColor={mapSettings.mapPaletteStartColor}
                          paletteEndColor={mapSettings.mapPaletteEndColor}
                          paletteCardinality={mapSettings.mapPaletteCardinality}
                          opacity={mapSettings.mapOpacity}
                          isLegendCollapsed={mapSettings.mapIsLegendCollapsed}
                          setSettings={onMapSettingsSet}
                          isFullscreen={isFullscreen}
                        />
                      )

                    } else if (viewerIdx >= 2 && chartLayout) {
                      return (
                        <Chart
                          chartId={chartId}
                          type={viewers(t)[viewerIdx].chartType}
                          jsonStat={dataset}
                          hiddenAttributes={nodeExtras?.HiddenAttributes}
                          layout={chartLayout}
                          timePeriodsByFreq={timePeriodsByFreq}
                          labelFormat={labelFormat}
                          showTrend={showTrend}
                          showCyclical={showCyclical}
                          decimalSeparator={decimalSeparator}
                          decimalPlaces={decimalPlaces}
                          chartSettings={chartSettings}
                        />
                      )

                    } else {
                      return (
                        <CustomEmpty/>
                      )
                    }
                  }
                })()}
              </div>
            </div>
          </Card>
        </div>
        <div id="data-viewer__footer" className={classes.footer}>
          <DataViewerFooter
            dataset={{
              nodeCode: nodeCode,
              categoryPath: categoryPath,
              datasetId: datasetId,
              datasetTitle: datasetTitle,
              viewId: viewId
            }}
            onDatasetsChange={handleStyle}
          />
        </div>
      </Call>

      <Dialog
        open={isCriteriaVisible}
        fullWidth
        maxWidth="md"
        onClose={handleCriteriaClose}
        aria-labelledby="criteria__title__container"
      >
        <CustomDialogTitle onClose={handleCriteriaClose}>
          <span id="criteria__title__container">
            {t("scenes.dataViewer.dialogs.criteria.title")}
          </span>
        </CustomDialogTitle>
        <DialogContent className={classes.criteriaContent}>
          {dimensions && isCriteriaVisible && (
            <Fragment>

              <Criteria
                nodeId={nodeId}
                datasetId={datasetId}
                dimensions={dimensions}
                freqDimension={freqDimension}
                mode={mode}
                type={type}
                codes={codes}
                isCodesFlat={isCodesFlat}
                codelists={codelists}
                areCodelistsFlat={areCodelistsFlat}
                codelistsLength={codelistsLength}
                criteria={criteria}
                timePeriod={timePeriod}
                isCriteriaValid={isCriteriaValid}
                setCriteriaValidity={setCriteriaValidity}
                defaultLastNPeriods={nodeExtras?.DefaultLastNPeriods}
              />

              <Dialog
                open={isCriteriaAlertVisible}
                fullWidth
                maxWidth="sm"
                onClose={onCriteriaAlertHide}
              >
                {(() => {
                  if (isEmptyData) {
                    return (
                      <CustomDialogTitle onClose={onCriteriaAlertHide}>
                        {t("scenes.dataViewer.errors.emptyData")}
                      </CustomDialogTitle>
                    )

                  } else if (isTooBigData) {
                    return (
                      <Fragment>
                        <CustomDialogTitle onClose={onCriteriaAlertHide}>
                          {t("scenes.dataViewer.dialogs.criteriaAlert.tooBigData.title")}
                        </CustomDialogTitle>
                        <DialogContent>
                          <div style={{width: "100%", textAlign: "end", marginTop: 16}}>
                            {t("scenes.dataViewer.dialogs.criteriaAlert.tooBigData.content")}
                          </div>
                        </DialogContent>
                      </Fragment>
                    )

                  } else if (isTooLongQuery) {
                    return (
                      <Fragment>
                        <CustomDialogTitle onClose={onCriteriaAlertHide}>
                          {t("scenes.dataViewer.dialogs.criteriaAlert.isTooLongQuery.title")}
                        </CustomDialogTitle>
                        <DialogContent>
                          <div style={{width: "100%", textAlign: "end", marginTop: 16}}>
                            {t("scenes.dataViewer.dialogs.criteriaAlert.isTooLongQuery.content")}
                          </div>
                        </DialogContent>
                      </Fragment>
                    )

                  } else {
                    return (
                      <CustomDialogTitle onClose={onCriteriaAlertHide}>
                        {t("scenes.dataViewer.errors.genericError")}
                      </CustomDialogTitle>
                    )
                  }
                })()}
                <DialogActions>
                  <Button autoFocus onClick={onCriteriaAlertHide}>
                    {t("commons.confirm.close")}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog
                open={isObsCountWarningVisible}
                fullWidth
                maxWidth="sm"
                onClose={() => setIsObsCountWarningVisible(false)}
              >
                <DialogContent>
                  {t("scenes.dataViewer.dialogs.obsCountWarning.content")}
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setIsObsCountWarningVisible(false)}>
                    {t("commons.confirm.cancel")}
                  </Button>
                  <Button
                    autoFocus
                    onClick={() => {
                      setIsObsCountWarningVisible(false);
                      handleCriteriaSubmit();
                    }}
                  >
                    {t("commons.confirm.confirm")}
                  </Button>
                </DialogActions>
              </Dialog>

            </Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCriteriaClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={() => (criteriaObsCount && criteriaObsCount > maxObservation)
              ? setIsObsCountWarningVisible(true)
              : handleCriteriaSubmit()
            }
            disabled={!isCriteriaValid}
          >
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isLayoutVisible && viewerIdx === 0}
        fullWidth
        maxWidth="md"
        onClose={handleLayoutClose}
      >
        <CustomDialogTitle onClose={handleLayoutClose}>
          {t("scenes.dataViewer.dialogs.tableLayout.title")}
        </CustomDialogTitle>
        <DialogContent>
          <TableLayout
            jsonStat={dataset}
            layout={tmpTableLayout}
            labelFormat={labelFormat}
            setLayout={setTmpTableLayout}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLayoutClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleLayoutSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isLayoutVisible && viewerIdx >= 2}
        fullWidth
        maxWidth="md"
        onClose={handleLayoutClose}
      >
        <CustomDialogTitle onClose={handleLayoutClose}>
          {t("scenes.dataViewer.dialogs.chartLayout.title")}
        </CustomDialogTitle>
        <DialogContent>
          <ChartLayout
            jsonStat={dataset}
            layout={tmpChartLayout}
            setLayout={setTmpChartLayout}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLayoutClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleLayoutSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDownloadWarningVisible}
        maxWidth="md"
        onClose={onDownloadHide}
      >
        <DialogContent>
          {t("scenes.dataViewer.dialogs.downloadFormat.content")}
        </DialogContent>
        <DialogActions>
          <Button onClick={onDownloadHide}>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isUnavailableViewWarningVisible}
        maxWidth="md"
        onClose={onUnavailableViewHide}
      >
        <DialogContent>
          {t("scenes.dataViewer.dialogs.unavailableView.content")}
        </DialogContent>
        <DialogActions>
          <Button onClick={onUnavailableViewHide}>
            {t("commons.confirm.confirm")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isChartSettingsVisible}
        maxWidth="md"
        fullWidth
        onClose={handleChartSettingsClose}
      >
        <CustomDialogTitle onClose={handleChartSettingsClose}>
          {t("scenes.dataViewer.dialogs.chartSettings.title")}
        </CustomDialogTitle>
        <DialogContent style={{height: 480}}>
          <ChartSettings
            jsonStat={dataset}
            settings={tmpChartSettings}
            onSettingsSet={setTmpChartSettings}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartSettingsClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleChartSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isMapSettingsVisible}
        maxWidth="md"
        fullWidth
        onClose={handleMapSettingsClose}
      >
        <CustomDialogTitle onClose={handleMapSettingsClose}>
          {t("scenes.dataViewer.dialogs.mapSettings.title")}
        </CustomDialogTitle>
        <DialogContent style={{height: 480}}>
          <MapSettings
            jsonStat={dataset}
            settings={tmpMapSettings}
            onSettingsSet={setTmpMapSettings}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleMapSettingsClose}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button autoFocus onClick={handleMapSettingsSubmit} color="primary">
            {t("commons.confirm.apply")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={exportFormat === DOWNLOAD_FORMAT_EXCEL}
        maxWidth="sm"
        fullWidth
        onClose={handleExportExcelHide}
      >
        <CustomDialogTitle onClose={handleExportExcelHide}>
          {t("scenes.dataViewer.dialogs.exportExcel.title")}
        </CustomDialogTitle>
        <DialogContent>
          {singleSheet === false && (getFilterCombinationCount(dataset, tableLayout?.filters) > exportConfig?.maxExcelSheets) && (
            <Alert severity="warning" className={classes.alert}>
              {t("scenes.dataViewer.dialogs.exportExcel.warning", {sheetsLimit: exportConfig?.maxExcelSheets})}
            </Alert>
          )}
          {(() => {
            const label = t("scenes.dataViewer.dialogs.exportExcel.export.label") + ":";

            const getTextWidthEl = $(`<span class="${classes.exportLabel}">`).css({visibility: 'hidden'}).appendTo('body').get(0);
            const labelWidth = getTextWidth(label, getTextWidthEl);
            $(getTextWidthEl).remove();

            return (
              <Fragment>
                <span className={classes.exportLabel}>
                  {label}
                </span>
                <Select
                  variant="outlined"
                  value={singleSheet}
                  style={{width: `calc(100% - ${labelWidth}px)`}}
                  onChange={ev => setSingleSheet(ev.target.value)}
                  SelectDisplayProps={{"aria-haspopup": true}}
                >
                  <MenuItem value={true}>{t("scenes.dataViewer.dialogs.exportExcel.export.values.singleSheet")}</MenuItem>
                  <MenuItem value={false}>{t("scenes.dataViewer.dialogs.exportExcel.export.values.multiSheet")}</MenuItem>
                </Select>
              </Fragment>
            )
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportExcelHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleExportExcelSubmit}>
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={exportFormat === DOWNLOAD_FORMAT_CSV}
        maxWidth="sm"
        fullWidth
        onClose={handleExportCsvHide}
      >
        <CustomDialogTitle onClose={handleExportCsvHide}>
          {t("scenes.dataViewer.dialogs.exportCsv.title")}
        </CustomDialogTitle>
        <DialogContent>
          {(() => {
            const exportLayout = viewerIdx === 0
              ? tableLayout
              : viewerIdx === 1
                ? mapLayout
                : chartLayout;

            return dataset && (
              <Grid container spacing={2}>
                {(
                  getFilterCombinationCount(dataset, exportLayout?.filters) > 1 ||
                  (exportLayout?.primaryDim?.[0] !== undefined && exportLayout.primaryDimValues.length < dataset.size[dataset.id.indexOf(exportLayout.primaryDim[0])]) ||
                  (exportLayout?.secondaryDim?.[0] !== undefined && exportLayout.secondaryDimValues.length < dataset.size[dataset.id.indexOf(exportLayout.secondaryDim[0])])
                ) && (
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.field}>
                      <TextField
                        select
                        label={t("scenes.dataViewer.dialogs.exportCsv.export.label")}
                        value={filteredDataset}
                        onChange={ev => setFilteredDataset(ev.target.value)}
                        variant="outlined"
                        SelectProps={{"aria-haspopup": true}}
                      >
                        <MenuItem value={true}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.filtered")}</MenuItem>
                        <MenuItem value={false}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.full")}</MenuItem>
                      </TextField>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormControl fullWidth className={classes.field}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.dialogs.exportCsv.labelFormat.label")}
                      value={csvParams?.labelFormat}
                      onChange={ev => setCsvParams({...csvParams, labelFormat: ev.target.value})}
                      variant="outlined"
                      SelectProps={{"aria-haspopup": true}}
                    >
                      <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.both")}</MenuItem>
                      <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.name")}</MenuItem>
                      <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.id")}</MenuItem>
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth className={`${classes.field} ${classes.fieldLastRow}`}>
                    <TextField
                      select
                      label={t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.label")}
                      value={csvParams?.colsSeparator}
                      onChange={ev => setCsvParams({...csvParams, colsSeparator: ev.target.value})}
                      variant="outlined"
                      SelectProps={{"aria-haspopup": true}}
                    >
                      {getColsSeparators(t).map(({value, label}, idx) => (
                        <MenuItem key={idx} value={value}>{label}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth className={`${classes.field} ${classes.fieldLastRow}`}>
                    <TextField
                      label={t("scenes.dataViewer.dialogs.exportCsv.textQualifier.label")}
                      value={csvParams?.textQualifier || ""}
                      onChange={ev => (ev.target.value || "").length <= 1
                        ? setCsvParams({...csvParams, textQualifier: ev.target.value})
                        : null
                      }
                      variant="outlined"
                      error={csvParams?.colsSeparator === csvParams?.textQualifier}
                      helperText={csvParams?.colsSeparator === csvParams?.textQualifier
                        ? t("scenes.dataViewer.dialogs.exportCsv.textQualifier.helperText")
                        : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )
          })()}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportCsvHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleExportCsvSubmit} disabled={csvParams?.colsSeparator === csvParams?.textQualifier}>
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(DataViewer);
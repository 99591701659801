import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {
  applyDashboardsDatasetFilter,
  clearDashboardsDashboards,
  fetchDashboardsDashboard,
  fetchDashboardsDataset,
  fetchDashboardsMapGeometries,
  setDashboardsDatasetFilter,
  submitDashboardDatasetDownload
} from "../../state/dashboard/dashboardActions";
import Call from "../../hocs/call";
import Dashboard from "../dashboard";
import DashboardFilters from "../dashboard-filters";
import {
  DASHBOARD_ELEM_FILTER_DIMENSION_KEY,
  DASHBOARD_ELEM_TYPE_KEY,
  DASHBOARD_ELEM_TYPE_VALUE_VIEW,
  DASHBOARD_ELEM_VALUE_KEY,
  getViewIdxFromRowAndCol
} from "../../utils/dashboards";
import _ from "lodash";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CustomEmpty from "../custom-empty";
import {localizeI18nObj} from "../../utils/i18n";
import {useTranslation} from "react-i18next";
import {CRITERIA_FILTER_TYPE_CODES} from "../../utils/criteria";
import {cancelPendingRequests} from "../../state/pending-request/pendingRequestActions";
import {useLocation} from "react-router";
import Page from "../page";
import {getPageTitle} from "../../utils/other";
import NodeHeader from "../node-header";
import Footer from "../footer";
import {HashLink} from 'react-router-hash-link';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import CustomLink from "../custom-link";
import {getHomeInternalUrl, getNodeInternalUrl} from "../../links";
import {
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID,
  LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME
} from "../label-format-selector/constants";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogTitle from "../custom-dialog-title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {DOWNLOAD_FORMAT_CSV, DOWNLOAD_FORMAT_EXCEL} from "../../utils/download";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    backgroundColor: "#f5f5f5",
    width: "100%",
    height: "100%",
    padding: "0 !important",
    overflow: "hidden"
  },
  fullWidthContainer: {
    padding: "0 8px",
    width: "100%",
    height: "100%"
  },
  breadcrumbs: {
    padding: "16px 8px 8px"
  },
  breadcrumbsSeparator: {
    margin: "0 4px"
  },
  breadcrumbsLastElem: {
    color: theme.palette.primary.main,
    padding: 4
  },
  dashboardTitle: {
    padding: "0 12px 4px",
    fontSize: 28,
    fontWeight: 300,
    letterSpacing: 0
  },
  commonsFilters: {
    padding: "0 12px"
  },
  dashboardHeader: {
    padding: "8px 4px"
  },
  dashboard: {
    overflow: "auto"
  },
  field: {
    marginBottom: theme.spacing(2)
  },
  fieldLastRow: {
    marginBottom: 0
  }
});

const mapStateToProps = state => ({
  hub: state.hub,
  exportConfig: state.hub.hub?.exportConfig || {},
  node: state.node,
  nodeId: state.node?.nodeId,
  catalog: state.catalog,
  dashboardConfig: state.dashboardConfig,
  isA11y: state.app.isA11y,
  defaultLanguage: state.app.language,
  languages: state.app.languages,
  dashboards: state.dashboard.dashboards,
  fetchedDashboards: state.dashboard.fetchedDashboards,
  jsonStats: state.dashboard.jsonStats,
  layouts: state.dashboard.layouts,
  filterTrees: state.dashboard.filterTrees,
  timePeriodsByFreq: state.dashboard.timePeriodsByFreq,
  maps: state.dashboard.maps,
  dynamicViewPendingRequests: state.dashboard.dynamicViewPendingRequests
});

const mapDispatchToProps = dispatch => ({
  fetchDashboard: dashboardId => dispatch(fetchDashboardsDashboard(dashboardId)),
  clearDashboards: () => dispatch(clearDashboardsDashboards()),
  fetchDataset: (dashboardId, isFetchingDynamicView, nodeId, datasetId, criteria, requestIds, worker) =>
    dispatch(fetchDashboardsDataset(dashboardId, isFetchingDynamicView, nodeId, datasetId, criteria, requestIds, worker)),
  onFilterSet: (dashboardId, viewIdx, dimension, value) => dispatch(setDashboardsDatasetFilter(dashboardId, viewIdx, dimension, value)),
  onFilterApply: () => dispatch(applyDashboardsDatasetFilter()),
  fetchMapGeometries: (dashboardId, viewIdx, idList, t, nodeId) => dispatch(fetchDashboardsMapGeometries(dashboardId, viewIdx, idList, t, nodeId)),
  onDownloadSubmit: ({nodeId, datasetId, datasetTitle, format, extension, zipped, criteria, layout, params, t}) =>
    dispatch(submitDashboardDatasetDownload(nodeId, datasetId, datasetTitle, format, extension, zipped, criteria, layout, params, t)),
  cancelPendingRequests: requestUuids => dispatch(cancelPendingRequests(requestUuids))
});

const handleHeight = () => {
  const dashboardsBreadcrumbsHeight = document.getElementById("dashboards__breadcrumbs")
    ? document.getElementById("dashboards__breadcrumbs").offsetHeight
    : 0;
  const dashboardsTitleHeight = document.getElementById("dashboards__title")
    ? document.getElementById("dashboards__title").offsetHeight
    : 0;
  const dashboardsFiltersHeight = document.getElementById("dashboards__filters")
    ? document.getElementById("dashboards__filters").offsetHeight
    : 0;
  const dashboardsHeaderHeight = document.getElementById("dashboards__header")
    ? document.getElementById("dashboards__header").offsetHeight
    : 0;
  const footerHeight = document.getElementById("footer")
    ? document.getElementById("footer").offsetHeight
    : 0;
  if (document.getElementById("dashboards__content")) {
    document.getElementById("dashboards__content").setAttribute(
      "style",
      `height: calc(100% - ${dashboardsBreadcrumbsHeight + dashboardsTitleHeight + dashboardsFiltersHeight + dashboardsHeaderHeight + footerHeight}px)`
    );
  }
};

const getIsFilterEnabled = (dashboard, dashboardConfig) => {
  if (!dashboard) {
    return false
  }

  let hasDynamicView = false;
  dashboard.dashboardConfig.forEach(row => {
    row.forEach(col => {
      if ((col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY] || "").length > 0) {
        hasDynamicView = true;
      }
    });
  });

  let lastLavorizableIdx = -1;
  (dashboardConfig?.labels || []).forEach((label, idx) => {
    if (dashboard?.filterLevels?.[dashboardConfig.labels[idx]] === true) {
      lastLavorizableIdx = idx;
    }
  });

  return (hasDynamicView && lastLavorizableIdx >= 0)
};

const COLS_SEPARATOR_COMMA = ",";
const COLS_SEPARATOR_SEMICOLON = ";";
const COLS_SEPARATOR_PIPE = "|";
const COLS_SEPARATOR_TAB = "\t";

const getColsSeparators = t => ([
  {
    value: COLS_SEPARATOR_COMMA,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.comma") : "Comma (,)"
  },
  {
    value: COLS_SEPARATOR_SEMICOLON,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.semicolon") : "Semicolon (;)"
  },
  {
    value: COLS_SEPARATOR_PIPE,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.pipe") : "Pipe (|)"
  },
  {
    value: COLS_SEPARATOR_TAB,
    label: t ? t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.values.tab") : "Tab ( )"
  }
]);

const getInitialCsvParams = csvSeparator => ({
  labelFormat: LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH,
  colsSeparator: (csvSeparator && getColsSeparators().map(({value}) => value).includes(csvSeparator))
    ? csvSeparator
    : COLS_SEPARATOR_COMMA,
  textQualifier: ""
});

function Dashboards(props) {
  const {
    classes,

    dashboardId,
    nodeCode,
    isDefault,

    hub,
    exportConfig,
    node,
    nodeId,
    catalog,
    dashboardConfig,
    isA11y,
    defaultLanguage,
    languages,
    dashboards,
    fetchedDashboards,
    jsonStats,
    layouts,
    filterTrees,
    timePeriodsByFreq,
    maps,
    dynamicViewPendingRequests,

    fetchDashboard,
    clearDashboards,
    fetchDataset,
    onFilterSet,
    onFilterApply,
    fetchMapGeometries,
    onDownloadSubmit,
    cancelPendingRequests
  } = props;

  const {t} = useTranslation();
  const location = useLocation();

  const [filterValue, setFilterValue] = useState("");
  const [selectValues, setSelectValues] = useState({});
  const [lastValorizedIdx, setLastValorizedIdx] = useState(-1);

  const [allowedDashboards, setAllowedDashboards] = useState(null);

  const [tabIdx, setTabIdx] = useState(0);

  const [exportArgs, setExportArgs] = useState(null);
  const [exportCsvParams, setExportCsvParams] = useState(null);
  const [filteredDataset, setFilteredDataset] = useState(false);

  const [worker] = useState(() => new Worker("./workers/fetchDashboardDatasetAsyncHandlerWorker.js"));

  useEffect(() => {
    return () => {
      if (worker) {
        worker.terminate();
      }
    }
  }, [worker]);

  useEffect(() => {
    handleHeight();
  }, [dashboards, selectValues, tabIdx]);

  useEffect(() => {
    window.addEventListener("resize", handleHeight);
    return () => window.removeEventListener("resize", handleHeight)
  }, []);

  useEffect(() => {
    if (hub && (!nodeCode || (node && catalog && nodeId === catalog.nodeId && node.code.toLowerCase() === nodeCode.toLowerCase()))) {

      const nodeDashboards = nodeId ? (hub.nodes.find(node => node.nodeId === nodeId).dashboards || []) : [];
      const hubDashboards = (hub.hub.dashboards || []);

      // const showHubDashboardsExtra = JSON.parse((node?.extras || []).find(({key}) => key === "ShowHubDashboards")?.value || null);
      const showHubDashboards = true; // (!nodeId || (nodeId && showHubDashboardsExtra === true));

      const newAllowedDashboards = dashboardId
        ? [{id: dashboardId}]
        : nodeId
          ? showHubDashboards
            ? _.uniqBy(hubDashboards.concat(nodeDashboards), "id")
            : nodeDashboards
          : hubDashboards
      setAllowedDashboards(newAllowedDashboards);

    } else {
      clearDashboards();
      setFilterValue("");
      setAllowedDashboards(null);
    }

    return () => {
      clearDashboards();
    }
  }, [hub, nodeId, nodeCode, node, catalog, dashboardId, clearDashboards]);

  const handleFilterApply = value => {
    if (value !== filterValue) {
      setFilterValue(value);
      onFilterApply();
      cancelPendingRequests(dynamicViewPendingRequests);
    }
  };

  const handleFilterReset = () => {
    setSelectValues({});
    setLastValorizedIdx(-1);
    handleFilterApply(dashboardConfig?.defaultValue || "");
  };

  const handleFetchDatasets = ({dashboardId, fetchStatic, fetchDynamic, dynamicFilterValue}) => {
    const requests = [];

    dashboards[dashboardId].dashboardConfig.forEach((row, rowIdx) => {
      row.forEach((col, colIdx) => {
        if (col[DASHBOARD_ELEM_TYPE_KEY] === DASHBOARD_ELEM_TYPE_VALUE_VIEW) {
          const view = dashboards[dashboardId].views[col[DASHBOARD_ELEM_VALUE_KEY]];

          if (view) {
            let newCriteria = (view?.criteria || {});

            let addRequest = false;

            const filterDimension = col[DASHBOARD_ELEM_FILTER_DIMENSION_KEY];

            if (!filterDimension || filterDimension.length === 0) {
              addRequest = fetchStatic;

            } else {
              if (fetchDynamic) {
                addRequest = true;
                if (dynamicFilterValue) {
                  newCriteria = {
                    ...newCriteria,
                    [filterDimension]: {
                      ...newCriteria[filterDimension],
                      id: filterDimension,
                      type: CRITERIA_FILTER_TYPE_CODES,
                      filterValues: [dynamicFilterValue]
                    }
                  };
                } else {
                  newCriteria = {
                    ...newCriteria,
                    [filterDimension]: {
                      ...newCriteria[filterDimension],
                      id: filterDimension,
                      type: CRITERIA_FILTER_TYPE_CODES,
                      filterValues: dashboardConfig?.defaultValue ? [dashboardConfig.defaultValue] : []
                    }
                  };
                }
              }
            }

            if (addRequest) {
              const request = requests.find(({nodeId, datasetId, criteria}) => {
                return (
                  nodeId === view.nodeId &&
                  datasetId === view.datasetId &&
                  _.isEqual(criteria, newCriteria)
                )
              });

              if (request === undefined) {
                requests.push({
                  nodeId: view.nodeId,
                  datasetId: view.datasetId,
                  criteria: newCriteria,
                  requestIds: [getViewIdxFromRowAndCol(rowIdx, colIdx)]
                });
              } else {
                request.requestIds.push(getViewIdxFromRowAndCol(rowIdx, colIdx));
              }
            }
          }
        }
      });
    });

    requests.forEach(({nodeId, datasetId, criteria, requestIds}) =>
      fetchDataset(dashboardId, fetchDynamic, nodeId, datasetId, criteria, requestIds, worker)
    );
  };

  const handleExportCsvShow = useCallback(exportArgs => {
    setExportArgs(exportArgs);
    setExportCsvParams(getInitialCsvParams(exportConfig.csvSeparator));
    setFilteredDataset(true);
  }, [exportConfig.csvSeparator]);

  const handleExportCsvHide = useCallback(() => {
    setExportArgs(null);
  }, []);

  const handleExportCsvSubmit = useCallback(() => {
    const exportLayout = Object.assign({}, exportArgs.layout);
    if (!filteredDataset) {
      exportLayout.filtersValue = null;
      exportLayout.primaryDimValues = null;
      exportLayout.secondaryDimValues = null;
    }

    onDownloadSubmit({
      ...exportArgs,
      layout: exportLayout,
      criteria: exportArgs.fullCriteria,
      fullCriteria: undefined,
      params: {
        ...exportArgs.params,
        ...exportCsvParams
      }
    });
    handleExportCsvHide();
  }, [exportArgs, exportCsvParams, filteredDataset, onDownloadSubmit, handleExportCsvHide]);

  const handleExportExcelSubmit = useCallback(exportArgs => {
    onDownloadSubmit({
      ...exportArgs,
      criteria: exportArgs.fullCriteria,
      fullCriteria: undefined
    });
  }, [onDownloadSubmit]);

  const handleExport = useCallback((exportArgs) => {
    if (exportArgs.format === DOWNLOAD_FORMAT_CSV) {
      handleExportCsvShow(exportArgs);
    } else if (exportArgs.format === DOWNLOAD_FORMAT_EXCEL) {
      handleExportExcelSubmit(exportArgs);
    } else {
      onDownloadSubmit(exportArgs);
    }
  }, [handleExportCsvShow, handleExportExcelSubmit, onDownloadSubmit]);

  const currentDashboardId = dashboardId
    ? dashboardId
    : allowedDashboards?.[tabIdx]?.id
      ? allowedDashboards[tabIdx].id
      : null;

  const params = new URLSearchParams(location.search);
  params.set("accessible", "true");
  const paramsStr = params.toString();
  const path = (nodeId && nodeCode)
    ? `/${window.language}/${nodeCode.toLowerCase()}`
    : `/${window.language}`;

  return (
    <Fragment>
      {!isA11y && (
        <a
          href={"./#" + path + (paramsStr.length > 0 ? "?" : "") + paramsStr}
          target="_self"
          className="skip-link sr-only"
        >
          {t("commons.hashLinks.accessible")}
        </a>
      )}
      <HashLink
        to={{hash: "#main", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.main")}
      </HashLink>
      <HashLink
        to={{hash: "#footer", search: location.search}}
        className="skip-link sr-only"
      >
        {t("commons.hashLinks.footer")}
      </HashLink>
      <Page
        title={node
          ? getPageTitle([t("scenes.dashboard.title", {name: node?.name}), hub?.hub?.name], t)
          : getPageTitle([t("scenes.dashboard.title", {name: hub?.hub?.name})], t)
        }
      >
        <div key={dashboardId} className={classes.root}>
          <NodeHeader
            noNode={!nodeId}
            hub={hub.hub}
            nodes={hub.nodes}
            node={nodeId ? node : undefined}
            nodeId={nodeId ? nodeId : undefined}
            isDefault={nodeId ? isDefault : undefined}
            title={nodeId ? node?.name : undefined}
            catalog={nodeId ? catalog : undefined}
            defaultNodeConfigOpen={props.defaultNodeConfigOpen}
            defaultAppConfigOpen={props.defaultAppConfigOpen}
            defaultUserConfigOpen={props.defaultUserConfigOpen}
            defaultNodesConfigOpen={props.defaultNodesConfigOpen}
            getCustomA11yPath={
              isA11y => {
                if (isA11y) {
                  if (nodeCode) {
                    return `/${window.language}/${nodeCode.toLowerCase()}`;
                  } else {
                    return `/${window.language}`;
                  }
                } else {
                  return false;
                }
              }
            }
          />
          <main id="main" className={classes.fullWidthContainer}>
            <div id="dashboards__breadcrumbs">
              <Breadcrumbs
                aria-label={t("components.results.breadcrumbs.ariaLabel")}
                classes={{
                  root: classes.breadcrumbs,
                  separator: classes.breadcrumbsSeparator
                }}
              >
                <CustomLink
                  to={getHomeInternalUrl()}
                  text={t("scenes.dashboards.breadcrumbs.home")}
                  textStyle={{padding: 4}}
                />
                {nodeId && (hub.nodes || []).length > 1 && (
                  <CustomLink
                    to={getNodeInternalUrl(node.code)}
                    text={node?.name || node.code}
                    textStyle={{padding: 4}}
                  />
                )}
                <span className={classes.breadcrumbsLastElem}>
                  {t("scenes.dashboards.breadcrumbs.dashboards")}
                </span>
              </Breadcrumbs>
            </div>
            {currentDashboardId
              ? (
                <Call
                  cb={fetchDashboard}
                  cbParam={currentDashboardId}
                  disabled={dashboards !== null && dashboards[currentDashboardId] !== null && dashboards[currentDashboardId] !== undefined}
                >
                  <Call
                    cb={handleFetchDatasets}
                    cbParam={{
                      dashboardId: currentDashboardId,
                      fetchStatic: true,
                      fetchDynamic: false,
                      dynamicFilterValue: null
                    }}
                    disabled={!dashboards || !dashboards[currentDashboardId] || fetchedDashboards?.[currentDashboardId]?.static === true}
                  >
                    <Fragment>
                      <div id="dashboards__title">
                        <Typography variant={"h1"} className={classes.dashboardTitle}>
                          {(allowedDashboards || []).length > 1
                            ? t("scenes.dashboard.title")
                            : localizeI18nObj(dashboards?.[currentDashboardId]?.title, defaultLanguage, languages)
                          }
                        </Typography>
                      </div>
                      <div id="dashboards__filters">
                        <div
                          className={classes.commonsFilters}
                          style={{
                            display: getIsFilterEnabled(dashboards?.[currentDashboardId], dashboardConfig)
                              ? "inherit"
                              : "none"
                          }}
                        >
                          <DashboardFilters
                            filters={dashboardConfig}
                            selectValues={selectValues}
                            setSelectValues={setSelectValues}
                            lastValorizedIdx={lastValorizedIdx}
                            setLastValorizedIdx={setLastValorizedIdx}
                            filterLevels={dashboards?.[currentDashboardId] ? dashboards[currentDashboardId].filterLevels : null}
                            onFilterApply={handleFilterApply}
                            onFilterReset={handleFilterReset}
                          />
                        </div>
                      </div>
                      {((allowedDashboards || []).length > 1) && (
                        <div id="dashboards__header" className={classes.dashboardHeader}>
                          <Paper>
                            <Tabs
                              value={tabIdx}
                              variant="scrollable"
                              scrollButtons="auto"
                              onChange={(_, newValue) => setTabIdx(newValue)}
                            >
                              {allowedDashboards.map(({id, title}, idx) =>
                                <Tab
                                  key={idx}
                                  label={title}
                                  id={`tab__${id}`}
                                  aria-controls={`dashboard__${id}`}
                                  tabIndex={0}
                                />
                              )}
                            </Tabs>
                          </Paper>
                        </div>
                      )}
                      <Call
                        cb={handleFetchDatasets}
                        cbParam={{
                          dashboardId: currentDashboardId,
                          fetchStatic: false,
                          fetchDynamic: true,
                          dynamicFilterValue: filterValue
                        }}
                        disabled={!dashboards || !dashboards[currentDashboardId] || fetchedDashboards?.[currentDashboardId]?.dynamic === true}
                      >
                        <div id="dashboards__content" className={classes.dashboard}>
                          {(allowedDashboards || []).map(({id}) =>
                            <div
                              key={id}
                              id={`dashboard__${id}`}
                              aria-labelledby={`tab__${id}`}
                            >
                              {id === currentDashboardId && (
                                <Dashboard
                                  dashboardId={id}
                                  dashboard={dashboards?.[id]}
                                  filterValue={(filterValue || "").length > 0 ? filterValue : (dashboardConfig?.defaultValue || "")}
                                  jsonStats={jsonStats?.[id] || null}
                                  layouts={layouts?.[id] || null}
                                  filterTrees={filterTrees?.[id] || null}
                                  timePeriodsByFreq={timePeriodsByFreq?.[id] || null}
                                  maps={maps?.[id] || null}
                                  onFilterSet={onFilterSet}
                                  fetchMapGeometries={fetchMapGeometries}
                                  onDownloadSubmit={handleExport}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </Call>
                    </Fragment>
                  </Call>
                </Call>
              )
              : (
                <CustomEmpty
                  text={nodeId
                    ? t("scenes.dashboard.noDashboard.node")
                    : t("scenes.dashboard.noDashboard.hub")
                  }
                />
              )
            }
            <Footer/>
          </main>
        </div>
      </Page>

      <Dialog
        open={exportArgs !== null}
        maxWidth="sm"
        fullWidth
        onClose={handleExportCsvHide}
      >
        <CustomDialogTitle onClose={handleExportCsvHide}>
          {t("scenes.dataViewer.dialogs.exportCsv.title")}
        </CustomDialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {exportArgs?.isFilterEnabled && (
              <Grid item xs={12}>
                <FormControl fullWidth className={classes.field}>
                  <TextField
                    select
                    label={t("scenes.dataViewer.dialogs.exportCsv.export.label")}
                    value={filteredDataset}
                    onChange={ev => setFilteredDataset(ev.target.value)}
                    variant="outlined"
                    SelectProps={{"aria-haspopup": true}}
                  >
                    <MenuItem value={true}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.filtered")}</MenuItem>
                    <MenuItem value={false}>{t("scenes.dataViewer.dialogs.exportCsv.export.values.full")}</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.field}>
                <TextField
                  select
                  label={t("scenes.dataViewer.dialogs.exportCsv.labelFormat.label")}
                  value={exportCsvParams?.labelFormat}
                  onChange={ev => setExportCsvParams({...exportCsvParams, labelFormat: ev.target.value})}
                  variant="outlined"
                  SelectProps={{"aria-haspopup": true}}
                >
                  <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_BOTH}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.both")}</MenuItem>
                  <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_NAME}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.name")}</MenuItem>
                  <MenuItem value={LABEL_FORMAT_SELECTOR_LABEL_FORMAT_ID}>{t("scenes.dataViewer.dialogs.exportCsv.labelFormat.values.id")}</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={`${classes.field} ${classes.fieldLastRow}`}>
                <TextField
                  select
                  label={t("scenes.dataViewer.dialogs.exportCsv.colsSeparator.label")}
                  value={exportCsvParams?.colsSeparator}
                  onChange={ev => setExportCsvParams({...exportCsvParams, colsSeparator: ev.target.value})}
                  variant="outlined"
                  SelectProps={{"aria-haspopup": true}}
                >
                  {getColsSeparators(t).map(({value, label}, idx) => (
                    <MenuItem key={idx} value={value}>{label}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={`${classes.field} ${classes.fieldLastRow}`}>
                <TextField
                  label={t("scenes.dataViewer.dialogs.exportCsv.textQualifier.label")}
                  value={exportCsvParams?.textQualifier || ""}
                  onChange={ev => (ev.target.value || "").length <= 1
                    ? setExportCsvParams({...exportCsvParams, textQualifier: ev.target.value})
                    : null
                  }
                  variant="outlined"
                  error={exportCsvParams?.colsSeparator === exportCsvParams?.textQualifier}
                  helperText={exportCsvParams?.colsSeparator === exportCsvParams?.textQualifier
                    ? t("scenes.dataViewer.dialogs.exportCsv.textQualifier.helperText")
                    : null
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportCsvHide}>
            {t("commons.confirm.cancel")}
          </Button>
          <Button onClick={handleExportCsvSubmit} disabled={exportCsvParams?.colsSeparator === exportCsvParams?.textQualifier}>
            {t("commons.confirm.download")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Dashboards);